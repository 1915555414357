import React from "react";
import {Button, Result, Row, Col} from 'antd';
import {NavLink} from "react-router-dom";


const PaymentCancel = () =>
    <Row
        justify="center"
        align="middle"
        style={{minHeight: "80vh", textAlign: "center"}}
    >
        <Col>
            <Result
                status="error"
                title="Payment has been cancelled!"
                subTitle="Try making payment again."
                extra={<NavLink to={'/'}>
                    <Button type="primary">Go to inventory</Button>
                </NavLink>}

            />
        </Col>
    </Row>

export default PaymentCancel;