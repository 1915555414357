import React from "react";
import {Row, Col, Typography, Button} from "antd";
import {NavLink} from "react-router-dom";

const {Title} = Typography;

const NotFoundPage = () => {
    return (
        <Row
            justify="center"
            align="middle"
            style={{minHeight: "80vh", textAlign: "center"}}
        >
            <Col>
                <Title level={1} style={{marginBottom: 20}}>404</Title>
                <Title level={3} style={{marginBottom: 40}}>Page not found</Title>
                <NavLink to="/">
                    <Button size="small" type="primary">
                        Go to home page
                    </Button>
                </NavLink>
            </Col>
        </Row>
    );
}

export default NotFoundPage;