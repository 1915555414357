import React, {useState} from "react";
import dayjs from "dayjs";
import restAPI from "../../../features/restAPI";
import {loadStripe} from '@stripe/stripe-js';

import {UserAddOutlined} from "@ant-design/icons";
import {Button, Space, Modal, Row, Col, Tag, Form, Select, Input, DatePicker, message} from 'antd';
import {FinalizedOrdersTable} from "./styled-components/FinalizedTable";
import {StatesAutoCompleteUseForm} from "../../../components/StatesAutoComplete";


const phoneNumberMask = value => {
    let x = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    return (value = !x[2]
        ? x[1]
        : `(${x[1]}) ${x[2]}${x[3] ? "-" + x[3] : ""}`);
}

const AR_PROVIDER_ID = 1157;
const AR_PROVIDER_PRICE = 17900;
const REGULAR_SELF_PHLEBOTOMY_PRICE = 19900;


// DEV key
// const stripePromise = loadStripe('pk_test_51NQX1MKbmzi38XjIgKLJeHOgtR8Sg6LKQaJxYTDmvC20zoUuRdBhDueZhdrJneVElw8qlGP2jKfCWqj8ioOoAWFY00fbuczKB2');

// LIVE key
const stripePromise = loadStripe('pk_live_51NQX1MKbmzi38XjIxakuqR9kjehxvOlJOnOsPNPqd9fvgkIbtqexLU11mjhUUkVP4LExn0FLZFTffkLKHEnUX0Iy00q7jY3QRr');

const ProductDisplay = ({name, price, barcode}) => {

    const handleSubmit = async (event) => {
        event.preventDefault();

        // const response = await fetch('http://0.0.0.0:8080/api/v0/stripe/create-checkout-session/', {
        const response = await fetch('https://django-api.efunctional.com/api/v0/stripe/create-checkout-session/', {
            method: 'POST', headers: {
                'Content-Type': 'application/json', 'Authorization': 'Bearer b4950c7d61f0976220c3754b81a78068690a9856'
            }, body: JSON.stringify({
                name, price, quantity: 1, barcode: barcode
            }),
        });

        const data = await response.json();
        if (data.error) {
            console.error(data.error);
            return;
        }

        const stripe = await stripePromise;
        const result = await stripe.redirectToCheckout({sessionId: data.id});

        if (result.error) {
            console.error(result.error.message);
        }
    };

    return <Row>
        <Col>
            <Button size={'small'} onClick={handleSubmit}>
                {/*Checkout ${price / 100}*/}
                Pay now
            </Button>
        </Col>
    </Row>;
};

const TotalKitsTable = ({data, authConfig, authContext}) => {

    const [form] = Form.useForm();

    const [isLoading, setIsLoading] = useState(false);
    const [assignInputValue, setAssignInputValue] = useState("");
    const [selectedKit, setSelectedKit] = useState(null);

    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);


    // State for Stripe
    const [sessionId, setSessionId] = useState(null);

    const sendFormWithMessage = async (values) => {
        try {
            const {email, date_of_birth} = values;

            const user = {
                username: email, email
            }

            const addressData = {
                address_1: values.address, city: values.city, state: values.state, postcode: values.postcode,
            }

            let dateStr = dayjs(date_of_birth).format('YYYY-MM-DD');
            let cleanedDate = dateStr.split('T')[0]; // This will give you '2023-09-15'

            const data = {
                ...values,
                date_of_birth: cleanedDate,
                address_data: addressData,
                user,
                kit_barcode: selectedKit.barcode,
            }

            console.log(data);
            // return

            // If the endpoint for `create_patient_with_shipping` is something like '/patients/create_patient_with_shipping/',
            // adjust the URL accordingly.
            const response = await restAPI.post('/patients/all/create_patient_with_shipping/', data);

            // Log the response for debugging purposes. You can also implement further processing of the response here.
            console.log(response.data);

            setOpen(false);

            message.success("Patient created successfully.");

            // You might also want to give feedback to the user, e.g.:
            // alert('Form submitted successfully!');

        } catch (error) {
            // Log the error for debugging. In a real-world scenario, you'd also likely want to give feedback to the user.
            console.error("Error submitting the form:", error);

            let errorMessage = "Server error. Please try again later.";

            if (error.response) {
                try {
                    // Try parsing the error response as JSON
                    const errorData = error.response.data;
                    const firstErrorField = Object.keys(errorData)[0];
                    errorMessage = errorData[firstErrorField][0];
                } catch (e) {
                    // If parsing as JSON fails, fall back to treating it as text
                    errorMessage = error.response.text || errorMessage;
                }
            }

            message.error(errorMessage);
        }
    }

    const calculatePrice = (currentObject, authContext) => {
        // Computed kit price with special cases. Order of conditions is important.
        const {
            id: providerId,
            is_self_phlebotomy: isProviderDoSelfPhlebotomy
        } = authContext?.providerData || {};
        const {isSelfPhlebotomy, test, orderItem} = currentObject || {};

        if (providerId === AR_PROVIDER_ID && test === 'MN') return AR_PROVIDER_PRICE;  // 1
        if (isProviderDoSelfPhlebotomy && test === 'MN') return REGULAR_SELF_PHLEBOTOMY_PRICE; // 2
        if (isSelfPhlebotomy && test === 'MN') return REGULAR_SELF_PHLEBOTOMY_PRICE; // 3
        return orderItem?.product?.price * 100; // 4
    };


    const showModal = selectedKit => {
        setSelectedKit(selectedKit);
        setOpen(true);
    };

    const handleCancel = () => {
        setAssignInputValue("");
        console.log('Clicked cancel button');
        setOpen(false);
    };

    const pagination = {position: ["bottom", ],}

    const columns = [

        {
            title: 'Test',
            dataIndex: 'orderItem',
            key: 'orderItem',
            render: (orderItem) => <>{orderItem?.product?.name}</>,
            responsive: ['xl'],
        },

        {
            title: 'Barcode',
            dataIndex: 'barcode',
            key: 'id', render: (text) => <>{text}</>,
            responsive: ['xs', 'lg', 'sm']
        },


        {
            title: 'Kit status', dataIndex: 'status', key: 'id', render: (text) => <>{text}</>, responsive: ['lg']
        },

        {
            title: 'Activated Date',
            dataIndex: 'dateActivated',
            key: 'address',
            render: (data, currentObject) => {
                if (currentObject?.status === 'Expired') return '-'

                return <>{dayjs(data).isValid() ? dayjs(data).format('YYYY-MM-DD') : "TBD"}</>
            },
            responsive: ['xl',],
        },

        {
            title: 'Patient account',
            dataIndex: 'assignedTo',
            render: (assignedTo, currentObject) => {

                if (currentObject?.status === 'Expired') return '-'

                return <Space size="small">
                    {currentObject?.patient_details
                        ? <>
                            {currentObject?.patient_details?.last_name}
                            {`${currentObject?.patient_details?.first_name[0]}.`}
                        </>
                        : <>

                            {/*          <Button
                            loading={isLoading}
                            onClick={() => showModal(currentObject)}
                            size={'small'}
                            icon={<UserAddOutlined/>}
                            type={'primary'}
                            // disabled={currentObject.status !== "Pending"}
                            disabled={true}
                        >
                            Assign to
                        </Button>*/}
                            TBD
                        </>
                    }
                </Space>
            },
            responsive: ['xs', 'lg']
        },

        {
            title: 'Is Paid', // dataIndex: 'assignedTo',
            render: (assignedTo, currentObject) => {

                if (currentObject?.status === 'Expired') return '-'

                return <Space size="small">
                    {/* Special case for MN test with `isSelfPhlebotomy`. Now it's hardcoded, but you can fix it */}
                    {currentObject?.isPaid ? <Tag color="#01a9ac">Paid</Tag> : <ProductDisplay
                        barcode={currentObject?.barcode}
                        name={currentObject?.orderItem?.product?.name}
                        // price={currentObject?.orderItem?.product?.price * 100}
                        // price={(currentObject?.isSelfPhlebotomy && currentObject?.test === 'MN') ? 199 * 100 : currentObject?.orderItem?.product?.price * 100}
                        price={calculatePrice(currentObject, authContext)}
                        sessionId={sessionId}
                        setSessionId={setSessionId}
                    />}
                </Space>

            }, responsive: ['xs', 'lg', 'sm']
        },];

    return <>
        <Modal
            title={<><UserAddOutlined/>&nbsp;&nbsp;Create a new patient account</>}
            open={open}
            onOk={() => form.submit()}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            okText={'Save'}
            width={window.innerWidth > 1200 ? 780 : '100%'}
        >

            <Form
                layout="vertical"
                form={form}
                onFinish={sendFormWithMessage}
                autoComplete="off"
            >
                <Row gutter={16}>
                    <Col lg={12} xs={24}>
                        <Form.Item
                            name="first_name"
                            label="First name"
                            rules={[{
                                required: true, message: 'Please input first name',
                            },]}
                        >
                            <Input placeholder={'First name'}/>
                        </Form.Item>
                    </Col>

                    <Col lg={12} xs={24}>
                        <Form.Item
                            name="last_name"
                            label="Last name"
                            rules={[{required: true, message: ''}]}
                        >
                            <Input placeholder={'Last name'}/>
                        </Form.Item>
                    </Col>

                    <Col lg={12} xs={24}>
                        <Form.Item
                            name="gender"
                            label="Sex"
                            rules={[{required: true, message: "Can't be empty"}]}
                        >
                            <Select
                                placeholder="Please choose a sex"
                                defaultOpen={0}
                                style={{width: '100%'}}
                                options={[{label: "Male", value: "M"}, {label: "Female", value: "F"},]}
                                // onChange={value => setFormData({...formData, employee_funding_periods: value})}
                            />
                        </Form.Item>
                    </Col>

                    <Col lg={12} xs={24}>
                        <Form.Item
                            name="date_of_birth"
                            label="Date of birth"
                            rules={[{required: true, message: ''}]}
                        >
                            <DatePicker style={{width: '100%'}} format={'MM-DD-YYYY'}/>
                        </Form.Item>
                    </Col>

                    <Col lg={12} xs={24}>
                        {/*<Form.Item*/}
                        {/*    name="email"*/}
                        {/*    label="Email"*/}
                        {/*    rules={[{*/}
                        {/*        required: true, message: 'Please input email',*/}
                        {/*    },]}*/}
                        {/*>*/}
                        {/*    <Input placeholder={'Email'}/>*/}
                        {/*</Form.Item>*/}


                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter email',

                                },
                                {
                                    // eslint-disable-next-line
                                    pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                                    message: 'Please enter valid email',
                                }
                            ]}
                        >
                            <Input
                                // onChange={({target: {value}}) => setFormData({...formData, email: value})}
                                placeholder="Please enter email"
                            />
                        </Form.Item>
                    </Col>

                    <Col lg={12} xs={24}>

                        <Form.Item
                            name="phone"
                            label="Phone"
                            rules={[
                                {required: true, message: 'Please enter phone',},
                                {pattern: /\((\d{3})\)\s(\d{3})-(\d{4})/, message: 'Please enter valid phone number',}
                            ]}
                        >
                            <Input
                                onChange={({target: {value}}) => {
                                    const prettyPhoneNumber = phoneNumberMask(value);

                                    form.setFieldValue('phone', prettyPhoneNumber);
                                }}
                                placeholder="Please enter phone"
                            />
                        </Form.Item>

                    </Col>


                    <Col lg={12} xs={24}>
                        <Form.Item
                            name="address"
                            label="Address"
                            rules={[{
                                required: true, message: 'Please input an address',
                            },]}
                        >
                            <Input placeholder={'Address'}/>
                        </Form.Item>
                    </Col>

                    <Col lg={12} xs={24}>
                        <Form.Item
                            name="city"
                            label="City"
                            rules={[{required: true, message: 'Please input a city'}]}
                        >
                            <Input placeholder={'City'}/>
                        </Form.Item>
                    </Col>

                    <Col lg={12} xs={24}>
                        <StatesAutoCompleteUseForm form={form}/>
                    </Col>

                    <Col lg={12} xs={24}>
                        <Form.Item
                            name="postcode"
                            label="Zip code"
                            rules={[{required: true, message: 'Please input a zip'}]}
                        >
                            <Input placeholder={'Zip code'}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>

        <FinalizedOrdersTable
            // width={'100%'}
            // sticky={true}
            size={'small'}
            columns={columns}
            dataSource={data}
            pagination={pagination}
            scroll={false}
        />
    </>
        ;
}

export default TotalKitsTable;