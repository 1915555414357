import apiService from './apiService';


export const loadAllProviderOrders = async (providerId, startDate = null, endDate = null) => {
    try {
        if (!providerId) {
            throw new Error('Provider ID is required');
        }

        let requestURL = `/orders/provider/?provider=${providerId}`;

        if (startDate && endDate) {
            requestURL += `&created__gte=${startDate}&created__lte=${endDate}`;
        }

        requestURL += `&ordering=-created`;

        const response = await apiService.get(requestURL);
        return response.data.results;

    } catch (error) {
        console.error('Error fetching orders:', error);
        throw new Error('Failed to fetch orders. Please try again later.', {originalError: error});
    }
};


export const createNewSupplyOrder = async (orderData) => {
    try {
        const response = await apiService.post(`/orders/save-new-provider-order/`, orderData);

        console.log(orderData)

        return false;

        return response.data;

    } catch (error) {
        console.error('Error fetching orders:', error);
        throw new Error('Failed to fetch orders. Please try again later.', {originalError: error});
    }
}