import React, {useState, useCallback, useEffect} from "react";
import {debounce} from 'lodash';
import {useInfiniteQuery} from 'react-query';
import {loadAllProviderPatients} from "../../../api/patientService"
import useStore from "../../store";
import InfiniteScroll from 'react-infinite-scroll-component';

import styled from "styled-components";
import pages from "../../../routing/config";

import {LoadingOutlined} from "@ant-design/icons";
import {Col, List, Row, Spin, AutoComplete, Input, Button, message, Typography, Space, Tag, Divider} from "antd";

import MainPageTitle from "../../../components/MainPageTitle";
import PatientDetails from "./PatientDetails";

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
// Use the plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const CustomAutoComplete = styled(AutoComplete)`
    & {
        width: 100%;
        margin: 0 0 10px 0;
    }

    & button {
        background: linear-gradient(to right, #01a9ac, #01dbdf);
    }

    & input, & input:focus {
        border-color: #01a9ac;
    }
`


const MyPatients = () => {
    const {myPatients} = pages;
    const providerData = useStore(state => state.providerData);
    const {id: providerId} = providerData;

    const [searchStr, setSearchStr] = useState("");
    const [open, setOpen] = useState(false);
    const [hasShownError, setHasShownError] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState(null);  // save a selected user in drawer


    const {data: patientResponseData, error, fetchNextPage, hasNextPage, isLoading} = useInfiniteQuery(
        ['clients', providerId, searchStr],
        ({pageParam = ''}) => loadAllProviderPatients(providerId, searchStr, pageParam),
        {getNextPageParam: (lastPage) => lastPage.next ? lastPage.next : false}
    );

    const loadMorePatients = async () => {
        if (hasNextPage) {
            await fetchNextPage();
        }
    };

    const debouncedSearch = useCallback(debounce((nextValue) => {
        setSearchStr(nextValue);
    }, 500), []); // 500ms debounce time


    const handleSearch = value => {
        if (!containsCyrillic(value)) {
            debouncedSearch(value);
        } else if (!hasShownError) {
            message.error("Cyrillic characters are not supported in search.");
            setHasShownError(true);
        }
    };

    // ======== //

    useEffect(() => {
        // Reset the error shown a flag whenever the search string changes
        setHasShownError(false);
    }, [searchStr]);


    const containsCyrillic = (text) => {
        const cyrillicPattern = /[\u0400-\u04FF]/;
        return cyrillicPattern.test(text);
    };

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
        setSelectedPatient(null);
    };

    const onClickHandler = (patient) => {
        setSelectedPatient({...patient});
        showDrawer();
    };

    const DetailsButton = patient => <Button
        type="primary"
        onClick={() => onClickHandler({...patient})}
        size={'small'}>
        Details
    </Button>


    const LastLoginTag = patient => {
        return <Row>
            <Col xs={0} lg={24} xl={24}>
                {patient?.user?.last_login
                    ? <Tag bordered={false} color={'rgba(47,173,176,0.09)'} style={{color: "#565353"}}>
                        Last login:&nbsp;&nbsp;
                        {dayjs(patient?.user?.last_login).tz('America/New_York').format('MM/DD/YYYY')}
                    </Tag>

                    : <Tag bordered={false} color={'#eedfdf'} style={{color: "#565353"}}>
                        Last login:&nbsp;&nbsp;Never
                    </Tag>}
            </Col>
        </Row>
    }

    const EmailTag = patient => {
        return <Col xs={0} lg={24} xl={24}>
            <Tag bordered={false} color={'rgba(238,206,47,0.24)'} style={{color: "#565353"}}>
                {patient?.user?.email ? patient?.user?.email : 'No email'}
            </Tag>
        </Col>
    }


    return <Spin spinning={isLoading} indicator={<LoadingOutlined size={'large'}/>}>
        <Row style={{padding: "0 10px"}}>

            <Col xs={24} lg={24} xl={24}>
                <MainPageTitle>{myPatients.title}</MainPageTitle>
            </Col>


            <Col xs={24} lg={24} xl={8}>
                <CustomAutoComplete>
                    <Input.Search
                        size="medium"
                        placeholder="Search ..."
                        enterButton
                        value={searchStr}
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                </CustomAutoComplete>
            </Col>


            <Col xs={24} lg={24} style={{minHeight: '63vh'}}>
                {patientResponseData?.pages?.flatMap(page => page.results).length !== undefined ? (
                    <InfiniteScroll
                        dataLength={patientResponseData?.pages?.flatMap(page => page.results).length}
                        next={loadMorePatients}
                        hasMore={hasNextPage}
                        endMessage={<Divider/>}
                        loader={<Typography.Paragraph style={{textAlign: "center"}}>Loading ...</Typography.Paragraph>}
                    >
                        <List
                            size={'small'}
                            loading={isLoading}
                            itemLayout="horizontal"
                            rowKey={(patient) => patient.id}
                            dataSource={patientResponseData?.pages?.flatMap(page => page.results)}
                            renderItem={(patient, index) =>
                                <List.Item
                                    key={index}
                                    actions={[EmailTag(patient), LastLoginTag(patient), DetailsButton(patient)]}
                                >
                                    <Space>
                                        <Typography.Text
                                            style={{cursor: 'pointer'}}
                                            color={'primary'} type={'text'} size={'small'}
                                            onClick={() => onClickHandler({...patient})}
                                        >
                                            {patient.last_name} {patient.first_name}
                                        </Typography.Text>
                                    </Space>
                                </List.Item>}
                        />
                    </InfiniteScroll>
                ) : (
                    <Space style={{width: '100%', justifyContent: 'center'}}><Spin/></Space>
                )}
            </Col>
        </Row>


        <PatientDetails
            setSelectedPatient={setSelectedPatient}
            selectedPatient={selectedPatient}
            open={open}
            onClose={onClose}
            providerId={providerId}
        />

    </Spin>
}

export default MyPatients;