import React, {useState, useEffect} from "react";
import {
    Button,
    Modal,
    List,
    InputNumber,
    message,
    Typography,
    Divider,
    Row,
    Col,
    Alert,
    Card,
    Statistic, Select, Tag
} from "antd";
import {
    CheckCircleOutlined,
    InfoCircleOutlined,
    DollarCircleOutlined,
} from "@ant-design/icons";
import useStore from "../../store";
import {createStripeSession} from "../../../api/createStripeSession";


const buildInitialValues = (productList, minimumQuantity) => {
    const initialValue = typeof minimumQuantity === 'number' ? 0 : 3;

    return productList.reduce((acc, product) => {
        acc[product.name] = initialValue;
        return acc;
    }, {});
}

const OrderConfirmationModal = ({orderId, investmentText, productList, totalQuantity, minimumQuantity}) => {
    const providerData = useStore(state => state.providerData);

    const [locationsList, setLocationsList] = useState(providerData.locations);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [chosenLocation, setChosenLocation] = useState(null);

    const [quantities, setQuantities] = useState(buildInitialValues(productList, minimumQuantity));


    const locationListOptions = locationsList.map(location => ({
        label: `${location?.state}, ${location?.city}, ${location?.postcode}, ${location?.address_1}  ${location?.address_2 || ''}`,
        value: location.id
    }))
    const updateQuantity = (product, value) => {
        setQuantities({...quantities, [product]: value});
    };

    const validateAndSubmit = async () => {

        try {
            const totalQuantity = Object.values(quantities).reduce((acc, val) => acc + val, 0);

            if (!chosenLocation) {
                message.error('Please choose location for order');
                return;
            }

            if (orderId === 3) {
                if (totalQuantity >= 5) {
                    message.success('Order is valid and has been submitted!');
                    // Submit the order or perform other actions


                    const cart = [...productData];

                    // Save needed address data fro current order
                    const currentLocation = locationsList.find(
                        location => location.id === chosenLocation
                    );

                    const orderData = {
                        provider_location: parseInt(chosenLocation),
                        provider: parseInt(providerData.id),

                        email: currentLocation?.location_manager_contact_email || providerData.email,
                        phone: currentLocation?.location_manager_contact_phone || providerData.phone,
                        firstName: currentLocation?.location_manager_first_name || providerData.name,
                        lastName: currentLocation?.location_manager_last_name || providerData.id,
                        address_1: currentLocation?.address_1,
                        address_2: currentLocation?.address_2 || '',
                        city: currentLocation?.city,
                        state: currentLocation?.state,
                        postcode: currentLocation?.postcode,

                        cart: JSON.stringify([...cart]),

                        name: "Provider Order",
                        price: cart.reduce((a, c) => a + c.quantity * c.price, 0) * 100,
                        // price: 100,
                        quantity: 1,  // means 1 order with one shopping cart on the Stripe side (a few products in the cart)
                    };

                    // console.log(orderData)

                    await createStripeSession(orderData);

                    return
                } else {
                    message.error('Total quantity must be 5 or more!');
                    return
                }
            }

            if (totalQuantity === 9) {
                message.success('Order is valid and has been submitted!');
                // Submit the order or perform other actions

                const cart = [...productData];

                console.log(cart)

                // Save needed address data for the current order
                const currentLocation = locationsList.find(
                    location => location.id === chosenLocation
                );

                const orderData = {
                    provider_location: parseInt(chosenLocation),
                    provider: parseInt(providerData.id),

                    email: currentLocation?.location_manager_contact_email || providerData.email,
                    phone: currentLocation?.location_manager_contact_phone || providerData.phone,
                    firstName: currentLocation?.location_manager_first_name || providerData.name,
                    lastName: currentLocation?.location_manager_last_name || providerData.id,
                    address_1: currentLocation?.address_1,
                    address_2: currentLocation?.address_2 || '',
                    city: currentLocation?.city,
                    state: currentLocation?.state,
                    postcode: currentLocation?.postcode,

                    cart: JSON.stringify([...cart]),

                    name: "Provider Order",
                    // price: cart.reduce((a, c) => a + c.quantity * c.price, 0) * 100,
                    // price: parseInt(investmentText.replace('$', '')) * 100,
                    price: parseInt(investmentText.replace(/[$,]/g, '')) * 100,
                    // price: 100,
                    quantity: 1,  // means 1 order with one shopping cart on the Stripe side (a few products in the cart)
                };
                // return
                await createStripeSession(orderData);

                return

            } else {
                message.error('Total quantity must be exactly 9!');
            }
        } catch (error) {
            console.error(error);
            message.error('Failed to submit the order. Please try again later.');
        }


    };

    const renderAlert = () => {
        if (orderId === 3) {
            const totalQuantity = Object.values(quantities).reduce((acc, val) => acc + val, 0);

            const alertType = totalQuantity >= 5
                ? 'success'
                : 'error';

            const alertMessage = totalQuantity >= 5
                ? 'You can proceed with the order!'
                : 'Total quantity must be 5 or more!';

            return <Alert type={alertType} message={alertMessage} style={{fontSize: 12, marginBottom: 15}}/>
        }

        const totalQuantity = Object.values(quantities).reduce((acc, val) => acc + val, 0);
        const alertType = totalQuantity === 9 ? 'success' : 'error';
        const alertMessage = totalQuantity === 9 ? 'Total quantity is exactly 9!' : 'Total quantity must be exactly 9!';

        return <Alert type={alertType} message={alertMessage} style={{fontSize: 12, marginBottom: 15}}/>
    }

    const totalOrderPrice = Math.ceil(productList.reduce((total, product) => {
        const quantity = quantities[product.name];
        const discountedPrice = product.price * 0.75; // 25% discount
        return total + (quantity * discountedPrice);
    }, 0));


    const productData = productList.reduce((total, product) => {
        const quantity = quantities[product.name];
        const discountedPrice = product.price * 0.75; // 25% discount
        total.push({
            product: product.productId,
            price: discountedPrice,
            quantity: quantity
        });
        return total;
    }, []);

    useEffect(() => {
        // fix bug on mobile devices with scroll
        if (isModalVisible) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [isModalVisible]);

    return <>
        <Button size={'large'} type={'primary'} onClick={() => setIsModalVisible(true)}>
            Buy now
        </Button>

        <Modal
            destroyOnClose={true}
            open={isModalVisible}
            // title="Order confirmation"
            onCancel={() => setIsModalVisible(false)}
            footer={[
                <Button size={'large'} key="back" onClick={() => setIsModalVisible(false)}>
                    Return to deals
                </Button>,
                <Button
                    size={'large'}
                    key="submit" type="primary"
                    onClick={validateAndSubmit}
                >
                    Pay now {orderId === 3 ? `$${totalOrderPrice}` : investmentText}
                </Button>,
            ]}
        >

            <Typography.Paragraph style={{fontSize: 13, display: 'flex', alignItems: 'center'}}>
                <InfoCircleOutlined style={{fontSize: 25, marginRight: '10px'}}/>
                <span>
                    {orderId === 3
                        ? `Please specify the number of kits you would like to purchase with 25% off wholesale. ${minimumQuantity} Kit minimum purchase required.`
                        : 'Please specify the number of kits you would like to purchase. The total number of kits should not exceed 9.'
                    }
                </span>
            </Typography.Paragraph>

            <List
                dataSource={productList.map(p => p.name)}
                renderItem={(item, i) => (
                    <List.Item>
                        <Row align="middle" justify="space-between">
                            <Col lg={20}>
                                <span style={{marginRight: 10}}>
                                    {item.toUpperCase()}
                                    {orderId === 3 &&
                                        <Tag style={{marginLeft: 10, fontSize: 11}}> ${productList[i].price}</Tag>}
                                </span>
                            </Col>

                            {/*<Col>*/}
                            {/*    <Divider type={'vertical'}/>*/}
                            {/*</Col>*/}

                            <Col lg={4}>
                                <InputNumber
                                    // style={{width: 60, float: 'right'}}
                                    size={'small'}
                                    min={0}
                                    max={orderId === 3 ? 99 : 9}
                                    value={quantities[item]}
                                    onChange={value => updateQuantity(item, value)}
                                />

                                {/*{orderId === 3 &&*/}
                                {/*    <span style={{marginLeft: 10, fontSize: 11}}>    x ${productList[i].price}</span>}*/}
                            </Col>
                        </Row>
                    </List.Item>
                )}
            />


            {renderAlert()}

            {/*<pre>*/}
            {/*    {JSON.stringify(quantities, null, 2)}*/}
            {/*</pre>*/}

            {/*<pre>*/}
            {/*    {JSON.stringify(productData, null, 2)}*/}
            {/*</pre>*/}

            {/*<Divider dashed={true}/>*/}

            {orderId === 3 &&
                <Row gutter={[10, 10]}>
                    <Col span={12}>
                        <Card size={'small'}>
                            <Statistic
                                title="Wholesale"
                                value={
                                    productList.reduce((total, product) => {
                                        const quantity = quantities[product.name];
                                        return total + (quantity * product.price);
                                    }, 0)
                                }
                                valueStyle={{
                                    fontSize: 12
                                }}
                                prefix={<DollarCircleOutlined/>}
                            />
                        </Card>
                    </Col>

                    <Col span={12}>
                        <Card size={'small'}>
                            <Statistic
                                title="Retail Sales"
                                value={
                                    productList.reduce((total, product) => {
                                        const quantity = quantities[product.name];
                                        return total + (quantity * product.directToConsumerPrice);
                                    }, 0)
                                }
                                valueStyle={{
                                    fontSize: 12
                                }}
                                prefix={<DollarCircleOutlined/>}
                            />
                        </Card>
                    </Col>

                    <Col span={12}>
                        <Card size={'small'}>
                            <Statistic
                                title="Final Price"
                                value={totalOrderPrice}
                                valueStyle={{
                                    color: '#2fadb0',
                                    fontSize: 12
                                }}
                                prefix={<DollarCircleOutlined/>}
                            />
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card size={'small'}>
                            <Statistic
                                title="Your Profit"
                                value={productList.reduce((total, product) => {
                                    const quantity = quantities[product.name];
                                    const profitPerProduct = product.directToConsumerPrice - product.price;
                                    return total + (quantity * profitPerProduct);
                                }, 0)
                                }
                                valueStyle={{
                                    color: '#2fadb0',
                                    fontSize: 12
                                }}
                                prefix={<DollarCircleOutlined/>}
                            />
                        </Card>
                    </Col>
                </Row>
            }


            {/*<Typography.Paragraph>*/}
            {/*    <Tag>Total wholesale:</Tag> $*/}

            {/*    {*/}
            {/*        productList.reduce((total, product) => {*/}
            {/*            const quantity = quantities[product.name];*/}
            {/*            return total + (quantity * product.price);*/}
            {/*        }, 0)*/}
            {/*    }*/}
            {/*</Typography.Paragraph>*/}

            {/*<Typography.Paragraph>*/}
            {/*    <Tag>Total Retail Sales:</Tag> $*/}
            {/*    {*/}
            {/*        productList.reduce((total, product) => {*/}
            {/*            const quantity = quantities[product.name];*/}
            {/*            return total + (quantity * product.directToConsumerPrice);*/}
            {/*        }, 0)*/}
            {/*    }*/}
            {/*</Typography.Paragraph>*/}


            {/*<Typography.Paragraph>*/}
            {/*    <Tag>Total price with 25% discount:</Tag> ${totalOrderPrice}*/}
            {/*</Typography.Paragraph>*/}


            {/*<Typography.Paragraph>*/}
            {/*    <Tag>Your Profit:</Tag> $*/}
            {/*    {productList.reduce((total, product) => {*/}
            {/*        const quantity = quantities[product.name];*/}
            {/*        const profitPerProduct = product.directToConsumerPrice - product.price;*/}
            {/*        return total + (quantity * profitPerProduct);*/}
            {/*    }, 0)*/}
            {/*    }*/}
            {/*</Typography.Paragraph>*/}


            <Divider dashed={true}>
                Where would you like to ship the order?
            </Divider>

            {/*            <Typography.Title level={5}>*/}
            {/*    Additional benefits*/}
            {/*</Typography.Title>*/}

            <Row gutter={[24, 24]}>
                <Col xs={24}>
                    <Select
                        // hidden={shoppingCart?.length === 0}
                        // disabled={isLoading}
                        placeholder="Please choose location for order"
                        style={{width: '100%'}}
                        options={locationListOptions}
                        onChange={setChosenLocation}
                        value={chosenLocation}
                    />
                </Col>
            </Row>

            <Divider dashed={true}>
                Additional benefits
            </Divider>

            {/*<Typography.Title level={5}>*/}
            {/*    Additional benefits*/}
            {/*</Typography.Title>*/}

            <Typography.Paragraph style={{fontSize: 11, display: 'flex', alignItems: 'center'}}>
                <CheckCircleOutlined style={{marginRight: '5px'}}/>
                <span>30 minute educational webinar for your staff to understand & sell the kits</span>
            </Typography.Paragraph>

            <Typography.Paragraph style={{fontSize: 11, display: 'flex', alignItems: 'center'}}>
                <CheckCircleOutlined style={{marginRight: '5px'}}/>
                <span>Marketing material - 50 4x9 Information Cards for Each Test Kit Purchased</span>
            </Typography.Paragraph>

            <Typography.Paragraph style={{fontSize: 11, display: 'flex', alignItems: 'center'}}>
                <CheckCircleOutlined style={{marginRight: '5px'}}/>
                <span>Access to Social media and digital content with Free branding during with their logos</span>
            </Typography.Paragraph>

        </Modal>
    </>

}

export default OrderConfirmationModal;