import React from "react";
import useNewDirectOrderStore from "../store";
import {DollarOutlined, ShoppingCartOutlined, UserAddOutlined} from "@ant-design/icons";
import {Steps, Typography} from "antd";
import ChooseProductComponent from "../ChooseProductComponent";
import AssignToPatientComponent from "../AssignToPatientComponent";
import CheckoutComponent from "../CheckoutComponent";


const StepperRouter = () => {
    const {currentStepperStep, steps: STEPS,} = useNewDirectOrderStore(state => state);

    if (currentStepperStep === STEPS.CHOOSE_PRODUCT) {
        return <ChooseProductComponent/>;
    }
    if (currentStepperStep === STEPS.ASSIGN_TO_PATIENT) {
        return <AssignToPatientComponent/>;
    }
    if (currentStepperStep === STEPS.CHECKOUT) {
        return <CheckoutComponent/>;
    }
    return null;
}

const StepperComponent = () => {
    const {currentStepperStep, steps: STEPS,} = useNewDirectOrderStore(state => state);

    const stepItems = [
        {
            title: <Typography.Text type={'secondary'}>Choose the product</Typography.Text>,
            icon: <ShoppingCartOutlined/>,
        },
        {
            title: <Typography.Text type={'secondary'}>Assign to patient</Typography.Text>,
            icon: <UserAddOutlined/>,
        },
        {
            title: <Typography.Text type={'secondary'}>Checkout</Typography.Text>,
            icon: <DollarOutlined/>,
        },
    ]

    return <React.Fragment>
        <Steps
            status={'process'}
            type={'default'}
            responsive={true}
            current={currentStepperStep}
            items={stepItems}
        />
        <StepperRouter/>
    </React.Fragment>
}


export default StepperComponent;