import restAPI from "../../../features/restAPI";
import pages from "../../../routing/config";
import React, {useState, useEffect} from 'react';
import {NavLink} from "react-router-dom";
import "../../../assets/old_css/style.css";

const ResetPassword = () => {
    /* Send email with secure link for `resetting password` */

    const [formData, setFormData] = useState({email: ""}); // form state
    const [submitErrors, setSubmitErrors] = useState(null);  // state for submit errors
    const [isSubmit, setIsSubmit] = useState(false);  // local component state
    const [isResetted, setIsResetted] = useState(false);  // page state

    const isValidEmail = () => {
        setSubmitErrors(null);
        const {email} = formData;
        const result = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);

        if (!result) {
            const error = 'Not valid email. Please fix it';

            setSubmitErrors(error);
            throw new Error(error);
        }
    }

    const stopMultipleSubmitting = () => {
        if (!isSubmit) return false;
    };

    const onSubmitHandler = async event => {
        event.preventDefault();
        setIsSubmit(true); // run resetting process
    }

    const sendRequestForResettingPassword = async () => {

        try {

            isValidEmail();  // check email
            stopMultipleSubmitting(); // save for multiple submitting

            await restAPI.post('/password-reset/', formData);  // send email with reset link to user
            setIsResetted(true);

        } catch (error) {

            console.error(error);

            if (error.hasOwnProperty('code')) {
                if (error.code === "ERR_BAD_REQUEST" && error?.response?.data?.email) {
                    setSubmitErrors(`${error?.response?.data?.email[0]}`);
                    return true;
                }
            }

            if (error.hasOwnProperty('code')) {
                if (error.code === "ERR_BAD_REQUEST") {
                    setSubmitErrors(`${error.message}. Try again later`);
                    return true;
                }
            }

            if (error.hasOwnProperty('response')) {
                setSubmitErrors(error.response?.data?.detail);
                return true;
            }

        } finally {
            setIsSubmit(false);
        }
    }


    useEffect(() => {

        if (isSubmit && !isResetted) {
            (async () => {
                sendRequestForResettingPassword();
            })();
        }

        // eslint-disable-next-line
    }, [isSubmit, isResetted]);


    return <div className="container">
        <div className="wrapper">
            <div className="signin">
                <div className="jbox-dark-sm">

                    <div className="jbox-dark-header">
                        <p>Reset password</p>
                    </div>

                    {isResetted === false

                        ? <>
                            <hr className="solid"/>
                            <div className="jbox-dark-paragraph">
                                <p>Please enter your email</p>
                                <br/>
                                {/*<br/>*/}
                            </div>

                            {submitErrors &&
                                <p style={{color: "red", textAlign: "center", background: 'white', padding: '7px 0'}}>
                                    {submitErrors}
                                </p>
                            }


                            <form
                                onSubmit={onSubmitHandler}
                                autoComplete="off"
                                className="input-group"
                                id="signin-patient"
                            >


                                <input
                                    disabled={isSubmit}
                                    autoComplete="off"
                                    onChange={({target: {value}}) => setFormData(prevState => {
                                        return {...formData, email: value};
                                    })}
                                    value={formData?.email || ""}
                                    className="input-field"
                                    id="signin-form-login"
                                    type="text"
                                    name="login"
                                    required
                                    placeholder="Email"
                                />

                                <button
                                    disabled={!isResetted && isSubmit}
                                    className="submit-btn"
                                    id="login" type="submit"
                                >
                                    Reset
                                </button>

                                {isSubmit && <p style={{textAlign: 'center', marginTop: 30, color: 'white'}}>
                                    loading ...
                                </p>}


                                <div className="jbox-dark-paragraph">
                                    <NavLink to={pages.login.url} style={{color: 'white'}}>
                                        <p>Return to {pages.login.title}</p>
                                    </NavLink>
                                </div>

                            </form>


                            <form
                                onSubmit={onSubmitHandler}
                                className="input-group-phone"
                                id="signin-patient-phone"
                            >

                                <input
                                    value={formData?.email || ""}
                                    onChange={({target: {value}}) => setFormData(prevState => {
                                        return {...formData, email: value};
                                    })}
                                    className="input-field-phone"
                                    id="signin-form-login-phone"
                                    type="text" name="login"
                                    required="required"
                                    placeholder="Email"
                                />

                                <button
                                    disabled={!isResetted && isSubmit}
                                    className="submit-btn-phone" id="login-phone" type="submit">
                                    Reset
                                </button>

                                {/* TODO forgot password */}

                                <div className="jbox-dark-paragraph">
                                    <NavLink to={pages.login.url} style={{color: "white"}}>
                                        <p>Return to {pages.login.title}</p>
                                    </NavLink>
                                </div>

                            </form>
                        </>


                        : <>

                            <div className="jbox-dark-paragraph">
                                <p>
                                    We have sent to you email with secure link for resetting your old password.<br/>
                                </p>

                                <p>
                                    Please check "{formData?.email}"
                                </p>
                            </div>
                        </>
                    }

                </div>
            </div>
        </div>
    </div>
        ;
}
export default ResetPassword;


