import React, {useContext} from "react";
import useNewDirectOrderStore from "../store";
import {AuthContext} from "../../../auth/AuthContext";
import {CheckOutlined, DoubleRightOutlined} from "@ant-design/icons";
import {Button, Divider, Form, Select, Tag, Typography} from "antd";


const {Option} = Select;

const ChooseProductComponent = () => {
    /*  Step 1  */

    const {
        productList,
        product: selectedProduct,
        setProduct: setSelectedProduct,
        moveToPatientCreatingStep,
    } = useNewDirectOrderStore(state => state);

    const {authContext} = useContext(AuthContext);

    //  Means this product list will not be shown in the list, but displayed on BO.
    const excluded = ["AP", "CE", "RA", "FA", "SK", "RS"];

    if (!productList) return null;

    // sort productList by product.price z-a
    productList.sort((a, b) => b.price - a.price);

    /* Prepare options for <Select/> component */
    const options = productList &&
        productList
            .filter(product => !excluded.includes(product.code))
            .map(product => ({
                value: product.id,
                label: <Typography.Text>
                    {product.name} <Divider type={'vertical'}/> <Tag color={"#01a9ac"}>${product.price}</Tag>

                    {/*{product.code === 'MN' && <>*/}
                    {/*    {authContext?.providerData?.is_self_phlebotomy*/}
                    {/*        ? <><Divider type={'vertical'}/> <Tag>Self Phlebotomy</Tag></>*/}
                    {/*        : <><Divider type={'vertical'}/> <Tag>Mobile Phlebotomy</Tag></>}*/}
                    {/*</>}*/}

                    {product.code === 'MN' &&
                        <><Divider type={'vertical'}/><Tag bordered={true} style={{fontSize: 11}}
                             color={product.isSelfPhlebotomy ? '#8fda6e' : '#50aced'}>
                            {product.isSelfPhlebotomy ? 'Self Phlebotomy' : 'Mobile Phlebotomy'}
                        </Tag></>
                    }

                </Typography.Text>
            }));


    /* Push product data to main component state */
    const selectOnChangeHandler = value => setSelectedProduct(productList.find(p => p.id === value))

    return <>
        <Typography.Title level={4} style={{fontWeight: 400, marginTop: 30}}>
            Choose the product
        </Typography.Title>

        <Form>
            <Form.Item>
                <Select
                    size={'medium'}
                    placeholder={'Select product'}
                    defaultValue={selectedProduct?.id || null}
                    onChange={selectOnChangeHandler}
                    menuItemSelectedIcon={<CheckOutlined/>}
                >
                    {options.map(({value, label}) =>
                        <Option key={value} value={value}>{label}</Option>)
                    }
                </Select>
            </Form.Item>
        </Form>

        <Button
            disabled={selectedProduct === null}
            type={'primary'}
            icon={<DoubleRightOutlined/>}
            onClick={moveToPatientCreatingStep}
        >
            Create the patient
        </Button>
    </>
};


export default ChooseProductComponent;