import {Button, Col, Form, Input, message, Row, Switch, Spin} from "antd";
import React, {useContext, useEffect, useState} from "react";
import restAPI from "../../../features/restAPI";
import {AuthContext} from "../../auth/AuthContext";
import {LoadingOutlined} from "@ant-design/icons";

const PersonalInformation = () => {

    const {authContext} = useContext(AuthContext); // auth data from current session
    const [form] = Form.useForm();

    const [formState, setFormState] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const authConfig = {
        headers: {
            Authorization: `ECL ${authContext?.access}`,
        },
    };


    const submitForm = async (formData) => {
        formData.active = true;

        await restAPI.patch(`/provider-clients/${authContext?.providerData?.id}/`, formData, authConfig)
            .then(response => {
                // setSalesList(prevState => prevState.map(oneGroup => oneGroup.id === id ? response.data : oneGroup));
                console.log("success promise");

            })
            .catch(err => console.log(err));
    };

    const onFinish = async (values) => {
        /* Submit valid form */
        setIsLoading(true);

        await submitForm(values)
            .then(() => {
                form.resetFields();
                message.success({
                    content: `Your information successfully updated!`, duration: 2,
                });
            })
            .catch(() => {
                message.error({
                    content: 'We have an errors!', duration: 2,
                });
            }).finally(() => {
                setIsLoading(false);
            })
    }

    const triggerSubmitHandler = () => form.submit();

    const onChange = value => {
        setFormState(value);
    }

    useEffect(() => {
        form.setFieldsValue({
            ...authContext?.providerData
        });
        // eslint-disable-next-line
    }, []);


    return <Spin tip="Loading..." spinning={isLoading} indicator={<LoadingOutlined size={'large'}/>}>

        <Row style={{padding: "0 10px"}}>


            <Col>
                <Form>
                    <Form.Item
                        label={'Edit mode: '}
                        name="name"
                    >
                        <Switch checked={formState} onChange={onChange}/>
                    </Form.Item>
                </Form>
            </Col>

            <Col xs={24} lg={24} style={{minHeight: '55vh'}}>

                <Form
                    disabled={!formState}
                    layout="vertical"
                    size={'middle'}
                    onFinish={onFinish}
                    form={form}
                    autoComplete="off"
                >

                    <Row gutter={[24, 24]}>


                        <Col xs={24} lg={8}>
                            <Form.Item
                                label={'Name'}
                                name="name"
                                rules={[{
                                    required: true, message: 'Please input last name!',
                                }]}
                            >
                                <Input placeholder="Name"/>
                            </Form.Item>
                        </Col>


                        <Col xs={12} lg={8}>
                            <Form.Item
                                label={'NPI'}
                                name="doctor_npi"
                                rules={[{
                                    required: true, message: 'Please input your NPI!',
                                }]}
                            >
                                <Input placeholder="First name"/>
                            </Form.Item>
                        </Col>


                        <Col xs={12} lg={8}>
                            <Form.Item
                                label={'Phone'}
                                name="phone"
                                rules={[{required: true, message: 'Please input email!'}]}
                            >
                                <Input placeholder="Email"/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={8}>
                            <Form.Item
                                label={'Email'}
                                name="email"
                                rules={[{required: true, message: 'Please input email!'}]}
                            >
                                <Input placeholder="Email"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <hr/>

                    <Row gutter={[24, 24]}>

                        <Col xs={24} lg={6}>
                            <Form.Item
                                label={'Address'}
                                name="address"
                                rules={[{
                                    required: true, message: 'Please input address!',
                                }]}
                            >
                                <Input placeholder="Address"/>
                            </Form.Item>
                        </Col>

                        <Col xs={10} lg={6}>
                            <Form.Item
                                label={'City'}
                                name="city"
                                rules={[{
                                    required: true, message: 'Please input city!',
                                }]}
                            >
                                <Input placeholder="City"/>
                            </Form.Item>
                        </Col>

                        <Col xs={5} lg={6}>
                            <Form.Item
                                label={'State'}
                                name="state"
                                rules={[{
                                    required: true, message: 'Please input state!',
                                }]}
                            >
                                <Input placeholder="State"/>
                            </Form.Item>
                        </Col>

                        <Col xs={9} lg={6}>
                            <Form.Item
                                label={'Postal code'}
                                name="zip"
                                rules={[{
                                    required: true, message: 'Please input postal code!',
                                }]}
                            >
                                <Input placeholder="State"/>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Button
                                hidden={!formState}
                                style={{marginBottom: 50,}}
                                type="primary"
                                loading={false}
                                onClick={triggerSubmitHandler}
                            >
                                Update information
                            </Button>
                        </Col>

                    </Row>

                </Form>

            </Col>
        </Row>
    </Spin>
}


export default PersonalInformation;