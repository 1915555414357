import React from "react";
import {Button, Result, Row, Col} from 'antd';
import {NavLink} from "react-router-dom";


const PaymentSuccessful = () =>
    <Row
        justify="center"
        align="middle"
        style={{minHeight: "80vh", textAlign: "center"}}
    >
        <Col>
            <Result
                status="success"
                title="The payment was successful!"
                subTitle="Thank you for your choice and cooperation!"
                extra={[
                    <NavLink to={'/'}>
                        <Button type="primary" key="console">
                            Go to inventory
                        </Button>
                    </NavLink>,
                ]}
            />
        </Col>
    </Row>

export default PaymentSuccessful;