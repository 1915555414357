import {create} from 'zustand'

const useNewDirectOrderStore = create(set => {

    const STEPS = {
        CHOOSE_PRODUCT: 0,
        ASSIGN_TO_PATIENT: 1,
        CHECKOUT: 2,
    };

    return {
        isLoading: true,
        setIsLoading: (isLoading) => set({isLoading}),

        currentStepperStep: 0,
        setCurrentStepperStep: (currentStepperStep) => set({currentStepperStep}),

        productList: [],
        setProductList: (productList) => set({productList}),

        product: null,
        setProduct: (product) => set({product}),


        newPatientData: null,
        setNewPatientData: (newPatientData) => set({newPatientData}),

        steps: STEPS,

        moveToPatientCreatingStep: () => {
            set({currentStepperStep: STEPS.ASSIGN_TO_PATIENT});
        },

        returnToChooseTheProduct: () => {
            set({currentStepperStep: STEPS.CHOOSE_PRODUCT});
        },

        moveToCheckoutStep: () => {
            set({currentStepperStep: STEPS.CHECKOUT});
        },

    }
});


export default useNewDirectOrderStore;