import React, {useState, useEffect, useContext} from "react";
import {useNavigate} from "react-router-dom";
import useLocalStorage from "../../../features/hooks/useLocalStorage";
import {AuthContext} from '../AuthContext';


const getAuthContext = () => {
    /* This function get current localstorage object by key - authContext.
    Result this function is an init state for AuthContex */

    const authContextString = localStorage.getItem('providerAuthContext');
    return JSON.parse(authContextString) || null;
}

const AuthRequired = ({children}) => {
    /* Custom Auth controller component  */

    const navigate = useNavigate();
    const {authContext} = useContext(AuthContext);

    useEffect(() => {
        if (authContext === null) {
            navigate('/login');
        }
    }, [authContext]);

    return children;
}

const AuthProvider = ({children}) => {
    const [isAuth, setIsAuth] = useLocalStorage('providerAuthContext', getAuthContext()); // get default value from LocalStorage

    const [authContext, setAuthContext] = useState(isAuth);

    const login = async (tokens, providerData) => {
        /*
        This function waiting from Login component two params:
        @tokens object
        @patientData object   */

        setIsAuth({...tokens, providerData});  // save to localstorage
        setAuthContext({...tokens, providerData}); // save tokens and parse user_id
    }

    const logout = () => {
        /* Logout function. Clear all localstorage keys and turn off AuthContex */
        setAuthContext(null); // change react context
        setIsAuth(null); // change localstorage
    }

    return (<AuthContext.Provider value={{authContext, login, logout}}>
        {children}
    </AuthContext.Provider>)
}


export {AuthProvider, AuthRequired}