import React, {useContext, useEffect} from "react";
import useNewDirectOrderStore from "./store";
import useLoadProducts from "./hooks/useLoadProducts";
import MainPageTitle from "../../../components/MainPageTitle";
import StepperComponent from "./StepperComponent";
import {AuthContext} from "../../auth/AuthContext";
import {LoadingOutlined} from "@ant-design/icons";
import {Col, Row, Spin} from "antd";

const NewDirectOrder = () => {
    const {
        isLoading,
        setProductList,
        setIsLoading,
    } = useNewDirectOrderStore(state => state);

    const {authContext} = useContext(AuthContext);

    const {
        data: productList,
        isLoading: isProductLoading
    } = useLoadProducts(authContext?.providerData?.id, authContext?.providerData?.is_self_phlebotomy);

    useEffect(() => {
        if (productList) {
            setProductList(productList);
        }
        setIsLoading(isProductLoading);
    }, [productList, isProductLoading]);

    return <Spin tip="Loading..." spinning={isLoading} indicator={<LoadingOutlined size={'large'}/>}>
        <Row gutter={[24, 0]}>
            <Col xs={24} lg={24} xl={24}>
                <MainPageTitle>Direct to Consumer</MainPageTitle>
            </Col>

            <Col xs={24} lg={24} xl={12} xxl={22} style={{minHeight: '67vh'}}>
                <StepperComponent/>
            </Col>
        </Row>
    </Spin>
};
export default NewDirectOrder;