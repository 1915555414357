import React, {useState, useEffect} from 'react';
import {Statistic, Row, Col} from 'antd';


const PromoTimer = () => {
    // Set your deadline date here
    const deadlineDate = new Date('2024-05-29T23:59:59');
    const deadlineTimestamp = deadlineDate.getTime();

    const [deadline, setDeadline] = useState(deadlineTimestamp);
    const [timeRemaining, setTimeRemaining] = useState(deadline - Date.now());

    useEffect(() => {
        const interval = setInterval(() => {
            const newTimeRemaining = deadline - Date.now();
            if (newTimeRemaining <= 0) {
                clearInterval(interval);
                setTimeRemaining(0);
            } else {
                setTimeRemaining(newTimeRemaining);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [deadline]);

    return (
        <Row gutter={16}>
            <Col span={6}>
                <Statistic title="Days" value={Math.floor(timeRemaining / (1000 * 60 * 60 * 24))}/>
            </Col>
            <Col span={6}>
                <Statistic title="Hours" value={Math.floor((timeRemaining / (1000 * 60 * 60)) % 24)}/>
            </Col>
            <Col span={6}>
                <Statistic title="Minutes" value={Math.floor((timeRemaining / 1000 / 60) % 60)}/>
            </Col>
            <Col span={6}>
                <Statistic title="Seconds" value={Math.floor((timeRemaining / 1000) % 60)}/>
            </Col>
        </Row>
    );
};

export default PromoTimer;
