import React, {useState} from "react";
import {Button, Col, Divider, Drawer, Form, Row, Select, Space, Typography} from "antd";
import {CloseOutlined} from "@ant-design/icons";
import {LOGO_EFUNC, VIT_LAB} from "../../../assets/images";
import {makeNewVitaminLabsOrder} from "../../../api/patientService";

const VitaminLabsDrawer = ({selectedKIT, setSelectedKIT, isDrawerOpened, setIsDrawerOpened, providerId}) => {

    const [isCreateOrderLoading, setIsCreateOrderLoading] = useState(false);
    const [kind, setKind] = useState(null);  // kind of vitamins
    const [daysSupply, setDaysSupply] = useState(null);  // vitamins days supply

    const onDrawerCloseHandler = () => {
        console.log('Clicked cancel button');
        setIsDrawerOpened(false);
        setSelectedKIT(null);
        setKind(null);
        setDaysSupply(null);
    }

    const kindChangeHandler = state => {
        console.log(state);
        setKind(state);
    }

    const daysSupplyChangeHandler = state => {
        console.log(state);
        setDaysSupply(state);
    }

    const handleOrder = async (action) => {
        if (isCreateOrderLoading || kind === null || daysSupply === null) return;
        await makeNewVitaminLabsOrder(selectedKIT, action, kind, daysSupply, providerId);
        setIsDrawerOpened(false);
        setSelectedKIT(null);
    }

    return (
        <Drawer
            destroyOnClose={true}
            size={'large'}
            placement={'right'}
            closeIcon={<CloseOutlined/>}
            width={window.innerWidth > 1200 ? '38%' : '92%'}
            closable={window.innerWidth <= 1200}
            onClose={onDrawerCloseHandler}
            open={isDrawerOpened}
        >
            <Row gutter={[12, 12]}>

                <Col span={'24'}>
                    <Row justify={'space-between'}>
                        <Col span={12}>
                            <img src={LOGO_EFUNC} alt="Efunctional logo" style={{width: '45px'}}/>
                        </Col>

                        <Col span={12}>
                            <img src={VIT_LAB} alt="VitaminLab logo" style={{width: '50%', float: 'right'}}/>
                        </Col>
                    </Row>
                </Col>

                <Col span={'24'}>
                    <Typography.Title
                        style={{
                            fontSize: 21,
                            fontWeight: 400,
                            textTransform: 'uppercase',
                            margin: '30px 0',
                            fontFamily: 'Montserrat',
                            color: '#353c4e'
                        }}>
                        Micronutrient Order Form
                    </Typography.Title>
                    <Divider style={{margin: '-30px 0 20px 0'}}/>
                </Col>

                <Col span={'24'}>
                    <Typography.Title style={{fontSize: 14, fontWeight: 400, textTransform: 'uppercase'}}>
                        <strong>ORDER OPTIONS</strong>
                    </Typography.Title>
                </Col>

                <Col span={'24'}>
                    <Form.Item name="supply" label="Supply">
                        <Select
                            value={kind}
                            placeholder={'How much days?'}
                            style={{width: '200px'}}
                            onChange={daysSupplyChangeHandler}
                            options={[{value: '90', label: '90 Days',}, {
                                value: '120', label: '120 Days',
                            }, {value: '180', label: '180 Days',},]}
                        />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Form.Item name="doseType" label="Dose Type">
                        <Select
                            value={daysSupply}
                            placeholder={'What kind?'}
                            style={{width: '200px'}}
                            onChange={kindChangeHandler}
                            options={[{value: 'powder', label: 'Powder',}, {
                                value: 'capsules', label: 'Veggie Capsules',
                            },]}
                        />
                    </Form.Item>

                </Col>

                <Divider style={{margin: '-20px 0 20px 0'}}/>

                <Col span={24}>
                    <Typography.Title style={{fontSize: 14, fontWeight: 400, textTransform: 'uppercase'}}>
                        <strong>YOUR CUSTOM SUPPLEMENTS</strong>
                    </Typography.Title>
                </Col>

                <Col span={24}>
                    {selectedKIT?.vitamins.map(vitamin => <Row style={{fontFamily: 'Montserrat', margin: '0 0 7px 0'}}>
                        <Col span={12} key={vitamin.id}>
                            <div>{vitamin.suggested}</div>
                        </Col>

                        <Col span={12}>
                            <strong>{vitamin.calc} {vitamin.frequency}</strong>
                        </Col>
                    </Row>)}
                </Col>

                <Divider/>

                <Col span={10} offset={8}>
                    <Space>
                        <Button
                            disabled={isCreateOrderLoading || kind === null || daysSupply === null}
                            onClick={() => handleOrder('buy')}>
                            Buy Now
                        </Button>

                        <Button
                            disabled={isCreateOrderLoading || kind === null || daysSupply === null}
                            type={'primary'} onClick={() => handleOrder('modify')}>
                            Add Ingredients
                        </Button>
                    </Space>
                </Col>


            </Row>
        </Drawer>
    )
}

export default VitaminLabsDrawer;