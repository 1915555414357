import {create} from 'zustand'

const useStore = create(set => {
    // Initialize state with data from Local Storage if available
    const localData = JSON.parse(localStorage.getItem('providerAuthContext'))?.providerData || null;

    return {
        providerData: localData,
        setProviderData: (providerData) => {
            set({providerData}); // Update Zustand store

            const providerAuthContext = localStorage.getItem('providerAuthContext');

            const newAuthContext = {
                ...JSON.parse(providerAuthContext),
                providerData: providerData
            };
            localStorage.setItem('providerAuthContext', JSON.stringify(newAuthContext));
        },

        // If I provide a CRUD for locations, then we need it for easy update data
        providerLocations: [],
        setProviderLocations: (providerLocations) => set({providerLocations}),
        // --- //

        // Make sense to upload this data after success login. This data needs it once and not changed
        providerProducts: [],
        setProviderProducts: (providerProducts) => set({providerProducts}),
        // --- //

        // No make sense to upload this data after success login
        //
        // providerPatients: [],
        // setProviderPatients: (providerPatients) => set({providerPatients}),
        //
        // providerOrders: [],
        // setProviderOrders: (providerOrders) => set({providerOrders}),
        //
        // providerKits: [],
        // setProviderKits: (providerKits) => set({providerKits}),
    };
});

export default useStore;


