import React, {useState} from "react";
import pages from "../../../routing/config";

import MainPageTitle from "../../../components/MainPageTitle";

import MN from '../../../assets/productImages/mn.webp';
import FS from '../../../assets/productImages/fs.webp';
import FE from '../../../assets/productImages/fe.webp';
import RS from '../../../assets/productImages/rs.webp';
import ST from '../../../assets/productImages/st.webp';
import CC from '../../../assets/productImages/cc.webp';
import WM from '../../../assets/productImages/wm.webp';


import styled from "styled-components";

import {useQuery} from 'react-query';
import {getAllProviderProducts} from '../../../api/productService';
import {createStripeSession} from '../../../api/createStripeSession';
import {createNewSupplyOrder} from '../../../api/orderService';
import useStore from "../../store";


import {
    LoadingOutlined,
    ShoppingCartOutlined,
    DeleteOutlined,
    DoubleRightOutlined,
    DollarOutlined,
} from "@ant-design/icons";
import {Col, Row, Card, Skeleton, Button, Space, Spin, Typography, Divider, Select, Tag, message} from "antd";

const {Meta} = Card;

const productImages = {
    'MN': MN,
    'FS': FS,
    'FE': FE,
    'RS': RS,
    'ST': ST,
    'CC': CC,
    'WM': WM,
}

const ShopCard = styled(Card)`
    transition: transform 0.3s ease-in-out;
    overflow: hidden;
    width: 100%;
    border: none;

    & .ant-card-cover {
        overflow: hidden;
    }

    & .ant-card-cover img {
        transform: scale(1.1);
        transition: transform 0.3s ease-in-out;
    }

    &:hover {
        transform: scale(1.05);
        cursor: pointer;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }

    & * {
        font-family: Montserrat, sans-serif;
        outline-color: #01a9ac;
    }

    & .ant-card-head-title {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 1px;
    }

    & .shopping-button {
        background: linear-gradient(to right, #01a9ac, #01dbdf);
    }

    & .ant-card-cover {
        width: 80%;
        margin: 0 auto;
        transition: 0.1s;
    }

    & .ant-card-head-title {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 1px;
        color: #01a9ac;
    }
`

const NewOrder = () => {
    const {newOrder} = pages;
    const providerData = useStore(state => state.providerData);
    const {id: providerId, is_self_phlebotomy: isSelfPhlebotomy} = providerData;

    const [shoppingCart, setShoppingCart] = useState([]);
    const [locationsList, setLocationsList] = useState(providerData.locations);

    const locationListOptions = locationsList.map(location => ({
        label: `${location?.state}, ${location?.city}, ${location?.postcode}, ${location?.address_1}  ${location?.address_2 || ''}`,
        value: location.id
    }))

    const [chosenLocation, setChosenLocation] = useState(null);

    const fetchProducts = () => getAllProviderProducts(providerId, isSelfPhlebotomy);
    const {
        data: productList,
        isLoading,
        // error
    } = useQuery(['productList', providerId, isSelfPhlebotomy], fetchProducts);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const onSubmitOfNewSupplyOrder = async () => {
        try {

            // check shopping cart, if - 0 -> stop
            if (shoppingCart.length === 0) {
                return false;
            }

            // Prepare cart data for backend saving
            const cart = shoppingCart.map(
                p => ({product: parseInt(p.id), quantity: p.qty})
            );

            // Save needed address data fro current order
            const currentLocation = locationsList.find(
                location => location.id === chosenLocation
            );

            const orderData = {
                cart: [...cart],  // all products with qty
                provider: parseInt(providerData?.id),
                provider_location: parseInt(chosenLocation),
                clientID: providerData?.id,
                name: providerData?.name,

                email: currentLocation?.location_manager_contact_email || providerData?.email,
                phone: currentLocation?.location_manager_contact_phone || providerData?.phone,
                firstName: currentLocation?.location_manager_first_name || providerData?.name,
                lastName: currentLocation?.location_manager_last_name || providerData?.id,

                address_1: currentLocation?.address_1,
                address_2: currentLocation?.address_2 || '',
                city: currentLocation?.city,
                state: currentLocation?.state,
                postcode: currentLocation?.postcode,

                isExpeditedShipping: false,
            };

            try {
                await createNewSupplyOrder(orderData);

                // If the function executes successfully, the cart will be reset and a success message will be shown.
                resetCart();

                message?.success({
                    content: 'Your order was successfully generated.', duration: 3,
                });
            } catch (error) {
                // Handle the error here. For example, you can log the error or show an error message.
                console.error(error);
                message?.error({
                    content: 'An error occurred while generating your order.', duration: 3,
                });
            }

        } catch (error) {
            console.log(error);
            if (error?.response?.data) {
                message?.error({
                    content: error?.response?.data, duration: 3,
                });
            }
            if (error?.messages) {
                message?.error({
                    content: error?.messages, duration: 3,
                });
            }
        }
    }

    const onSubmitPayNowOrder = async () => {

        // check shopping cart, if - 0 -> stop
        if (shoppingCart.length === 0) {
            return false;
        }

        try {
            setIsClicked(true);
            setIsSubmitting(true);

            // Prepare cart data for request
            const cart = shoppingCart.map(p => ({product: parseInt(p.id), quantity: p.qty, price: p.price}));

            // Save needed address data fro current order
            const currentLocation = locationsList.find(
                location => location.id === chosenLocation
            );

            const orderData = {
                provider_location: parseInt(chosenLocation),
                provider: parseInt(providerId),

                email: currentLocation?.location_manager_contact_email || providerData.email,
                phone: currentLocation?.location_manager_contact_phone || providerData.phone,
                firstName: currentLocation?.location_manager_first_name || providerData.name,
                lastName: currentLocation?.location_manager_last_name || providerData.id,
                address_1: currentLocation?.address_1,
                address_2: currentLocation?.address_2 || '',
                city: currentLocation?.city,
                state: currentLocation?.state,
                postcode: currentLocation?.postcode,

                cart: JSON.stringify([...cart]),

                name: "Provider Order",
                price: shoppingCart.reduce((a, c) => a + c.qty * c.price, 0) * 100,
                // price: 100,
                quantity: 1,  // means 1 order with one shopping cart
            };

            await createStripeSession(orderData);

        } catch (error) {
            console.log(error)
        } finally {
            setIsSubmitting(false);
        }
    }

    const addToCart = product => {
        const exist = shoppingCart.find((x) => x.id === product.id);

        if (exist && exist.qty < 3) {
            setShoppingCart(shoppingCart.map(x => x.id === exist.id ? {...exist, qty: exist.qty + 1} : x));
        }

        if (!exist) {
            setShoppingCart([...shoppingCart, {...product, qty: 1}]);
        }
    }

    const removeFromCart = product => {
        const exist = shoppingCart.find((x) => x.id === product.id);
        if (exist.qty === 1) {
            setShoppingCart(shoppingCart.filter((x) => x.id !== product.id));
        } else {
            setShoppingCart(shoppingCart.map((x) => x.id === product.id ? {...exist, qty: exist.qty - 1} : x));
        }
    }

    const resetCart = () => {
        setShoppingCart([]);
        setChosenLocation(null);
    }

    return <Spin
        tip="Loading..."
        spinning={isLoading || isClicked || isSubmitting}
        indicator={<LoadingOutlined size={'large'}/>}
    >

        <Row gutter={[24, 0]}>
            <Col xs={24} lg={24} xl={24}>
                <MainPageTitle>{newOrder.title}</MainPageTitle>
            </Col>

            {/*<Col>*/}
            {/*    {JSON.stringify(productList)}*/}
            {/*</Col>*/}

            <Col xs={24} lg={24} xl={16}>
                <Row gutter={[24, 24]}>

                    {productList && productList
                        // fix it! Need to work from the backend or one instance
                        .filter(product => !["AP", "CE", "RA", "FA", "SK", "RS",].includes(product.code))
                        .map(product =>
                            <Col xs={24} md={12} lg={12} xl={8} xxl={8} key={product.id}>
                                <ShopCard
                                    // title={product.name}
                                    hoverable={false}
                                    bordered={false}
                                    // cover={<img src={productImg} alt={product.name}/>}
                                    cover={<img
                                        src={productImages[product.code] || productImages['CC']}
                                        alt={product.name}
                                    />}
                                    actions={[
                                        <Row gutter={[16]} justify={'center'} content={'center'}>

                                            {/*<Col style={{position: "absolute", top: -50}}>*/}
                                            {/*    {product.code === 'MN' && <>*/}
                                            {/*        {isSelfPhlebotomy*/}
                                            {/*            ? <><Tag style={{fontSize: 10}}>Self Phlebotomy</Tag></>*/}
                                            {/*            : <><Tag style={{fontSize: 10}}>Mobile Phlebotomy</Tag></>}*/}
                                            {/*    </>}*/}
                                            {/*</Col>*/}

                                            <Col style={{position: "absolute", top: -50}}>
                                                {product.code === 'MN' &&
                                                    <Tag bordered={false} style={{fontSize: 10}}
                                                         color={product.isSelfPhlebotomy ? '#8fda6e' : '#50aced'}>
                                                        {product.isSelfPhlebotomy ? 'Self Phlebotomy' : 'Mobile Phlebotomy'}
                                                    </Tag>
                                                }
                                            </Col>

                                            {/*isSelfPhlebotomy*/}


                                            <Col xs={24} style={{
                                                margin: "7px 0 15px 0",
                                                padding: "0 10px",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                                fontSize: "10px",
                                            }}>
                                                <Typography.Text>
                                                    {product.name}
                                                </Typography.Text>
                                            </Col>

                                            <Col>
                                                <Space>
                                                    {shoppingCart?.find(x => x.id === product.id && x.qty >= 1)
                                                        ? <Space size={'large'}>
                                                            <Button size={'small'}
                                                                    onClick={() => removeFromCart(product)}
                                                                    type="primary">
                                                                -
                                                            </Button>

                                                            <span>{shoppingCart?.find(x => (x.id === product.id))?.qty}</span>

                                                            <Button size={'small'} type="primary"
                                                                    onClick={() => addToCart(product)}>
                                                                +
                                                            </Button>
                                                        </Space>

                                                        : <Space>
                                                            <Button size={'small'} className={'shopping-button'}
                                                                    type="primary"
                                                                    icon={<ShoppingCartOutlined/>}
                                                                    onClick={() => addToCart(product)}
                                                            >
                                                                Add to cart
                                                            </Button>
                                                        </Space>
                                                    }

                                                    <Typography.Text>
                                                        <Tag style={{background: 'white', color: "black"}}>
                                                            $ {product.price}
                                                        </Tag>
                                                    </Typography.Text>
                                                </Space>
                                            </Col>
                                        </Row>,

                                    ]}
                                >
                                </ShopCard>
                            </Col>)}
                </Row>
            </Col>


            <Col xs={24} sm={24} md={24} xlg={6} xl={8}>

                <Card
                    width={'100%'}
                    title={"Your order"}
                    hoverable={true}
                    bordered={true}
                    actions={[<>
                        <Row gutter={[24, 24]}>
                            <Col xs={24}>
                                <Select
                                    hidden={shoppingCart?.length === 0}
                                    disabled={isLoading}
                                    placeholder="Please choose location for order"
                                    style={{width: '75%'}}
                                    options={locationListOptions}
                                    onChange={setChosenLocation}
                                    value={chosenLocation}
                                />
                            </Col>
                        </Row>


                        {/*<Space size={'middle'} style={{marginTop: 60}}>*/}


                        <Row gutter={[12, 24]} style={{marginTop: 40, padding: "0 10px"}}>

                            <Col xs={24}>
                                {providerData?.is_able_to_use_the_supply_orders
                                    ? <Button
                                        style={{width: '100%'}}
                                        size={'small'}
                                        icon={<DoubleRightOutlined/>}
                                        onClick={onSubmitOfNewSupplyOrder}
                                        hidden={chosenLocation === null}
                                        type={'primary'}
                                        disabled={shoppingCart.length === 0}
                                    >
                                        Supply order
                                    </Button>
                                    : null
                                }
                            </Col>

                            <Col xs={24}>
                                <Button
                                    style={{width: '100%'}}
                                    size={'small'}
                                    icon={<DollarOutlined/>}
                                    onClick={onSubmitPayNowOrder}
                                    hidden={chosenLocation === null}
                                    type={'primary'}
                                    disabled={shoppingCart.length === 0}
                                >
                                    Pay now
                                </Button>
                            </Col>

                            <Col xs={24}>
                                <Button
                                    size={'small'}
                                    hidden={chosenLocation === null}
                                    className={'shopping-button'}
                                    icon={<DeleteOutlined/>}
                                    onClick={resetCart}>
                                    Reset cart
                                </Button>

                            </Col>
                        </Row>

                        {/*</Space>*/}
                    </>,]}
                >
                    <Skeleton loading={isLoading} active>
                        <Meta
                            title={null}
                            description={<>
                                {shoppingCart.length !== 0
                                    ? shoppingCart.map(p => <Row key={p.id}>
                                        <Col xs={15}>
                                            {p.name}
                                        </Col>

                                        <Col xs={6}>
                                            $ {p.price}
                                        </Col>

                                        <Col xs={3}>
                                            {p.qty}
                                        </Col>
                                    </Row>)
                                    : <Row gutter={[24]}>
                                        <Col sm={24}>
                                            Cart is empty. <br/>
                                            Please select your desired test(s) and
                                            add them to your cart to place an order
                                        </Col>
                                    </Row>}
                                {shoppingCart.length !== 0 && <>
                                    <Divider/>

                                    <Row>
                                        <Col>
                                            Total: $ {shoppingCart.reduce((a, c) => a + c.qty * c.price, 0)}
                                        </Col>
                                    </Row>
                                </>

                                }
                            </>}
                        />
                    </Skeleton>
                </Card>
            </Col>
        </Row>
    </Spin>
}

export default NewOrder;