import React, {useState} from "react";
import {useQuery} from 'react-query';
import dayjs from "dayjs";
import pages from "../../../routing/config";
import useStore from "../../store";
import {loadAllProviderOrders} from "../../../api/orderService";
import {LoadingOutlined, ProfileOutlined, CalendarOutlined, CloseOutlined} from "@ant-design/icons";
import {Col, Row, Spin, List, Skeleton, Space, DatePicker, Button, Drawer, Divider} from "antd";
import MainPageTitle from "../../../components/MainPageTitle";


const History = () => {
    const {history} = pages;
    const providerData = useStore(state => state.providerData);

    const [selectedOrder, setSelectedOrder] = useState(null);
    const [isDrawerOpened, setIsDrawerOpened] = useState(false);
    const [month, setMonth] = useState(null);

    const fetchOrders = () =>
        loadAllProviderOrders(providerData?.id);
    const {
        data: ordersList,
        isLoading,
        error // todo handle error
    } = useQuery(['orders', providerData?.id], fetchOrders);


    const showKitsListForCurrentOrder = currentOrder => {
        setSelectedOrder(currentOrder);
        setIsDrawerOpened(true);
    }

    const closeDrawerHandler = () => {
        setIsDrawerOpened(false);
        setSelectedOrder(null);
    }

    const onChangeMonthHandler = value => {
        const currentDate = dayjs(value);

        if (currentDate.isValid()) {
            const startDate = currentDate.startOf('month').format('YYYY-MM-DD');
            const endDate = currentDate.endOf('month').format('YYYY-MM-DD');

            setMonth({startDate, endDate});
            return;
        }
        setMonth(null);
    }

    const ErrorBlock = () => {
        // shows error from unsuccessful request
        if (error) return <Col xs={24} lg={24} xl={24}>
            <Space style={{margin: '0 0 20px 0'}}>An error occurred: {error.message}</Space>
        </Col>
    }

    return <Spin tip="Loading..." spinning={isLoading} indicator={<LoadingOutlined size={'large'}/>}>

        <Drawer
            closeIcon={<CloseOutlined/>}

            // width={window.innerWidth > 1200 ? '80%' : '92%'}
            // closable={window.innerWidth <= 1200}
            onClose={closeDrawerHandler}
            open={isDrawerOpened}
            title={`Kits list for orderID: ${selectedOrder?.id}`}
        >
            <p>
                <strong>Total kits:</strong> {selectedOrder?.order_kits.length}
            </p>

            <Divider/>

            {selectedOrder?.order_kits.map(kit => <>
                <div>Kit barcode: {kit.barcode}</div>
                <div>Status: {kit.status}</div>
                <div>Test name: {kit?.orderItem?.product?.name}</div>
                <Divider/>
            </>)}
        </Drawer>

        <Row style={{padding: "0 10px"}}>

            <Col xs={24} lg={24} xl={24}>
                <MainPageTitle>{history.title}</MainPageTitle>
            </Col>

            <ErrorBlock/>

            {/*<Col xs={24} lg={24} xl={24}>*/}
            {/*    <Space style={{margin: '0 0 20px 0'}}>*/}
            {/*        <span>Filter orders by month:</span>*/}

            {/*        <DatePicker*/}
            {/*            // defaultValue={dayjs()}*/}
            {/*            onChange={onChangeMonthHandler}*/}
            {/*            format={'YYYY-MM'}*/}
            {/*            picker="month"*/}
            {/*            inputReadOnly={true}*/}
            {/*            size={'small'}*/}
            {/*        />*/}
            {/*    </Space>*/}

            {/*</Col>*/}

            <Col xs={24} lg={24} style={{minHeight: '63vh'}}>

                <List
                    className="demo-loadmore-list"
                    loading={isLoading}
                    itemLayout="horizontal"
                    dataSource={ordersList}
                    renderItem={(item) => (<List.Item
                        key={item.id}
                        actions={[

                            // <p key={`list-created-date-${item.id}`}>
                            //     <CalendarOutlined/> {dayjs(item?.created).format('MM-DD-YYYY')}
                            // </p>,

                            <Row>
                                <Col xs={0} sm={24}>
                                    <p key={`list-created-date-${item.id}`}>
                                        <CalendarOutlined/> {dayjs(item?.created).format('MM-DD-YYYY')}
                                    </p>
                                </Col>
                            </Row>,

                            <Button
                                size={'small'}
                                key={`show-kits-list-for-order-${item.id}`}
                                onClick={() => showKitsListForCurrentOrder(item)}
                            >
                                Details
                            </Button>,

                        ]}
                    >
                        <Skeleton avatar title={false} loading={isLoading} active>
                            <List.Item.Meta
                                // avatar={<ProfileOutlined style={{fontSize: 40}}/>}
                                title={<span>Order ID: {item?.id}</span>}
                                description={`Total kits: ${item?.order_kits.length}`}
                            />
                        </Skeleton>


                    </List.Item>)}
                />
            </Col>
        </Row>
    </Spin>
}

export default History;