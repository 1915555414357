import React, {useState} from "react";
import dayjs from "dayjs";
import useNewDirectOrderStore from "../store";
import {createStripeSessionForDirectToConsumerOrder} from "../../../../api/createStripeSession";
import {checkThePromoCodeAndUseIt} from "../../../../api/checkThePromoCodeAndUseIt";
import {DoubleLeftOutlined, DoubleRightOutlined, ManOutlined, WomanOutlined} from "@ant-design/icons";
import {Button, Col, Divider, Form, Row, Space, Typography, Modal, message, Input, Tag} from "antd";
import useStore from "../../../store";


const CheckoutComponent = () => {
    /*  Step 3  */

    const providerData = useStore(state => state.providerData);

    const {id: providerId, is_self_phlebotomy: isSelfPhlebotomy} = providerData;

    const {
        product,
        newPatientData,
        setNewPatientData,
        moveToPatientCreatingStep,
    } = useNewDirectOrderStore(state => state);

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [promoCodeData, setPromoCodeData] = useState(null);

    const [promoCodeValue, setPromoCodeValue] = useState('');
    const [isPromoCodeModalVisible, setIsPromoCodeModalVisible] = useState(false);

    const checkAndUsePromoCode = async () => {
        // Handler for checking a promo code
        try {
            setConfirmLoading(true);
            // Check if promo code is defined
            if (!promoCodeValue || promoCodeValue.length === 0) throw new Error('Promo code is not defined');

            const data = {
                code: promoCodeValue,
                client_id: providerId
            }

            // Check if promo code is valid
            const response = await checkThePromoCodeAndUseIt(data);

            // console.log(response)
            console.log(response?.valid)

            if (response?.valid !== true) throw new Error('Promo code is not valid');

            // Set promo code data
            setPromoCodeData(response);

            // Close the modal
            setIsPromoCodeModalVisible(false);

        } catch (error) {
            console.error(error);
            message.error('Promo code is not valid');
        } finally {
            setConfirmLoading(false);
            // setIsPromoCodeModalVisible(false);
        }
    }

    // checkThePromoCodeAndUseIt

    // const checkPromoCode = async () => {
    //     // Handler for checking a promo code
    //     try {
    //         // Check if promo code is defined
    //         if (!promoCodeData) throw new Error('Promo code is not defined');
    //
    //         // Check if promo code is valid
    //         const {data} = await checkPromoCodeRequest(promoCodeData);
    //         if (!data?.valid) throw new Error('Promo code is not valid');
    //
    //         // Set promo code data
    //         setPromoCodeData(data);
    //
    //     } catch (error) {
    //         console.error(error);
    //         message.error('Promo code is not valid');
    //     }
    // }

    const getPatientAddress = () => {
        // Handler for getting a patient address for couple cases.
        //  .existedPatient - it's a code helper field to understand if a patient is already in the system.
        let address;
        if (newPatientData?.existedPatient) {
            const {shipping_addresses} = newPatientData;
            address = shipping_addresses[0]?.address_1;
            address += shipping_addresses[0]?.address_2 ? `, ${shipping_addresses[0]?.address_2}` : '';
            address += shipping_addresses[0]?.city ? `, ${shipping_addresses[0]?.city}` : '';
            address += shipping_addresses[0]?.state ? `, ${shipping_addresses[0]?.state}` : '';
            address += shipping_addresses[0]?.postcode ? `, ${shipping_addresses[0]?.postcode}` : '';
        } else {
            const {address: address_1, city, state, postcode} = newPatientData;
            address = `${address_1}, ${city ? `, ${city}` : ''}${state ? `, ${state}` : ''}${postcode ? `, ${postcode}` : ''}`;
        }
        return address;
    }

    const getPatientGender = () => {
        // Handler for getting a patient gender for render the markup.
        const checkGenderIcon = genderString => {
            // Helper function for getting a gender icon
            if (genderString.toLowerCase() === 'm' || genderString.toLowerCase() === "male") return <ManOutlined/>;
            if (genderString.toLowerCase() === 'f' || genderString.toLowerCase() === "female") return <WomanOutlined/>;
            return null;
        }

        const {gender, first_name: firstName, last_name: lastName} = newPatientData;
        return <>{firstName} {lastName} {checkGenderIcon(gender)}</>;
    }


    const payAndSaveOrderHandler = async () => {
        /* Current request sends data to the Stripe  */

        const quantity = 1;

        try {
            const preparedAddressDataObjects = () => {
                // Handler for getting a patient address for couple cases.
                //  .existedPatient - it's a code helper field to understand if a patient is already in the system.
                if (newPatientData?.existedPatient) {
                    const {shipping_addresses} = newPatientData;


                    console.log(shipping_addresses);

                    const [defaultShippingAddress,] = shipping_addresses;
                    const address = defaultShippingAddress?.address_1 + (defaultShippingAddress?.address_2 ? `, ${defaultShippingAddress?.address_2}` : '');
                    const {city, state, postcode} = defaultShippingAddress;
                    return {address, city, state, postcode}
                }
                const {address, city, state, postcode} = newPatientData || {};
                return {address, city, state, postcode}
            }


            const preparedGenderValue = () => {
                // Handler for getting a patient gender.
                const {gender} = newPatientData || {};

                const getGenderString = genderString => {
                    // Helper function for getting a gender icon
                    if (gender.toLowerCase() === 'm' || gender.toLowerCase() === "male") return 'Male';
                    if (gender.toLowerCase() === 'f' || gender.toLowerCase() === "female") return 'Female';
                    return null;
                }

                return getGenderString(gender)
            }

            // Prepare patient data for the request
            const patientData = {
                ...newPatientData,
                ...preparedAddressDataObjects(),
                gender: preparedGenderValue(),
                date_of_birth: dayjs(newPatientData.date_of_birth).format('YYYY-MM-DD'),
            }

            // Check if webProvider ID is defined
            if (!providerData?.id) throw new Error('Provider ID is not defined');

            // Prepare webProvider ID for the request
            const {id: providerId} = providerData;
            const {name, id: productId, price: productPrice} = product;
            // const price = productPrice * 100; // Stripe requires price in cents
            // const price = productPrice * 100; // Stripe requires price in cents
            // const price = 100; // Stripe requires price in cents

            const discountRate = promoCodeData?.discount; // get discount rate from promo code data
            let finalPrice = productPrice;

            // Check if promo code exists and is valid
            if (discountRate) {
                const discountAmount = (productPrice * discountRate) / 100; // calculate discount amount
                finalPrice = productPrice - discountAmount; // subtract discount from original price
            }

            const price = finalPrice * 100; // Stripe requires price in cents
            // const price =  100; // Stripe requires price in cents


            // Prepare data for the request to the API for creating a Stripe session.
            const orderData = {
                name,
                productId,
                price,
                quantity,
                patientData,
                providerId
            }

            console.log("orderData", orderData);
            message.loading('Redirecting to the payment page...');

            await createStripeSessionForDirectToConsumerOrder(orderData);

        } catch (error) {
            console.error(error);
            message.error('Something went wrong. Please try again later.');
        }
    }

    return <Row>

        <Col xxl={24}>
            <Typography.Title level={4} style={{fontWeight: 400, marginTop: 30}}>
                Order confirmation and payment
            </Typography.Title>
        </Col>

        <Col xxl={24}>

            {newPatientData && <Row>

                <Col xxl={24} style={{marginBottom: 30}}>
                    <Typography.Title level={5}>
                        Product information:
                    </Typography.Title>


                    <Typography.Text>
                        Test name:
                        <Divider type={'vertical'}/>
                        {product.name}
                    </Typography.Text>

                    <br/>

                    <Typography.Text>
                        Price:
                        <Divider type={'vertical'}/>

                        {promoCodeData?.discount
                            ? <>${product.price - (product.price * promoCodeData?.discount) / 100}</>
                            : <>${product.price}</>}
                    </Typography.Text>

                    <br/>

                    <Typography.Text>
                        Test code:
                        <Divider type={'vertical'}/>
                        {product.code}
                    </Typography.Text>
                    <br/>

                    {promoCodeData === null && <Typography.Text>
                        Do you have a promo code?
                        <Divider type={'vertical'}/>
                        <Typography.Link onClick={setIsPromoCodeModalVisible}>
                            Click here to apply</Typography.Link>
                    </Typography.Text>}

                    {promoCodeData?.valid === true && <>
                        <Typography.Text>
                            <Tag color={'#01a9ac'}>Applied promo code: {promoCodeData?.discount}%</Tag>
                        </Typography.Text>
                    </>}

                </Col>


                <Col xxl={24} style={{marginBottom: 30}}>
                    <Typography.Title level={5}>
                        Patient information:
                    </Typography.Title>

                    <Typography.Text>
                        Full name:
                        <Divider type={'vertical'}/>
                        {newPatientData.first_name} {newPatientData.last_name} {newPatientData.gender === 'M' ?
                        <ManOutlined/> : <WomanOutlined/>}
                    </Typography.Text>

                    <br/>

                    <Typography.Text>
                        Date of birth:
                        <Divider type={'vertical'}/>
                        {dayjs(newPatientData?.date_of_birth).format('MM-DD-YYYY')}
                    </Typography.Text>

                    <br/>

                    <Typography.Text>
                        Email:
                        <Divider type={'vertical'}/>
                        {newPatientData.email}
                    </Typography.Text>

                    <br/>

                    <Typography.Text>
                        Phone:
                        <Divider type={'vertical'}/>
                        {newPatientData.phone}
                    </Typography.Text>

                    <br/>

                    <Typography.Text>
                        Address:
                        <Divider type={'vertical'}/>
                        {getPatientAddress()}
                    </Typography.Text>
                </Col>


                <Col xxl={24}>
                    <Typography.Title level={5}>Shipping information:</Typography.Title>

                    <Typography.Text>
                        Order receiver:
                        <Divider type={'vertical'}/>
                        {newPatientData.first_name} {newPatientData.last_name}
                    </Typography.Text>

                    <br/>

                    <Typography.Text>
                        Delivery address:
                        <Divider type={'vertical'}/>
                        {getPatientAddress()}
                    </Typography.Text>

                </Col>
            </Row>}


        </Col>

        <Col lg={24} xs={24}>
            <Form.Item>
                <Space>
                    <Button
                        style={{marginTop: 30}}
                        onClick={() => {

                            if (newPatientData?.existedPatient) {
                                setNewPatientData(null);
                                moveToPatientCreatingStep();
                                return;
                            }

                            moveToPatientCreatingStep();

                        }}
                        type={'dashed'}
                        icon={<DoubleLeftOutlined/>}
                    >
                        Return to patient information
                    </Button>

                    <Button
                        style={{marginTop: 30}}
                        onClick={payAndSaveOrderHandler}
                        type={'primary'}
                        icon={<DoubleRightOutlined/>}
                    >
                        Pay now
                    </Button>
                </Space>
            </Form.Item>
        </Col>

        <Modal
            closable={false}
            open={isPromoCodeModalVisible}
            centered={true}
            onCancel={() => setIsPromoCodeModalVisible(false)}
            onOk={async () => {
                await checkAndUsePromoCode()
            }}
            okText={'Apply'}
            loading={true}
            confirmLoading={confirmLoading}
        >
            <Typography.Paragraph>
                Promo code
            </Typography.Paragraph>

            <Input
                placeholder={'Enter promo code'}
                onChange={e => setPromoCodeValue(e.target.value)}
            />
        </Modal>

    </Row>
};

export default CheckoutComponent;