import apiService from './apiService';


export const checkThePromoCodeAndUseIt = async (orderData) => {
    try {
        const response = await apiService.post(`/promocodes/validate_promo_code/`, orderData);
        console.log(response)
        return response.data;

    } catch (error) {
        console.error('Error on checking the promo code:', error);
        throw new Error('Failed to check the promo code. Please try again later.', {originalError: error});
    }
}

