import React, {useState, useEffect, useContext} from "react";
import styled from "styled-components";
import pages from "../../../routing/config";

import {LoadingOutlined} from '@ant-design/icons';
import {Button, Col, DatePicker, Row, Segmented, Space, Spin, Divider} from "antd";

import MainPageTitle from "../../../components/MainPageTitle";
import InventoryRouterComponent from "./InventoryRouterComponent";

import restAPI from "../../../features/restAPI";
import {AuthContext} from "../../auth/AuthContext";
import dayjs from "dayjs";


const MySegmentedElement = styled(Segmented)`
    margin: 0 0 20px 0;

    & .ant-segmented-item-selected {
        background: linear-gradient(to right, #01a9ac, #01dbdf);
        color: #f5f5f5;
    }

    & .ant-segmented-thumb-motion-appear {
        background: linear-gradient(to right, #01a9ac, #01dbdf);
        color: #f5f5f5;
    }
`
const InventoryItem = styled.div`
    & span {
        display: block;
        font-size: 20px;
        font-weight: 500;
        padding: 5px 0 10px 0;
    }

    @media only screen and (max-width: 575px) {
        font-size: 12px !important;
    }
`


// const initDateForFiltering = () => {
//     const currentDate = dayjs();
//
//     const startDate = currentDate.startOf('month').format('YYYY-MM-DD');
//     const endDate = currentDate.endOf('month').format('YYYY-MM-DD');
//
//     return ({startDate, endDate})
//
// }

const Inventory = () => {
    const {inventory} = pages;

    const [isLoading, setIsLoading] = useState(true);

    // const [month, setMonth] = useState(initDateForFiltering() || null);
    const [month, setMonth] = useState(null);
    const [currentInventory, setCurrentInventory] = useState(null);

    const [ordersList, setOrdersList] = useState(null);
    const [finalizedKits, setFinalizedKits] = useState(null);
    const [activatedKits, setActivatedKits] = useState(null);
    const [pendingKits, setPendingKits] = useState(null);
    const [expiredKits, setExpiredKits] = useState(null);

    const [totalKitsValue, setTotalKitsValue] = useState(null);
    const [totalPending, setTotalPending] = useState(null);
    const [totalActivated, setTotalActivated] = useState(null);
    const [totalFinalized, setTotalFinalized] = useState(null);
    const [totalExpired, setTotalExpired] = useState(null);


    const [locationsList, setLocationsList] = useState(null);
    const [locationsListOptions, setLocationsListOptions] = useState(null);
    const [chosenLocation, setChosenLocation] = useState(null);

    const {authContext} = useContext(AuthContext); // auth data from current session

    const authConfig = {
        headers: {
            Authorization: `ECL ${authContext?.access}`,
        },
    };

    const loadAllProviderLocations = async () => {

        try {
            setIsLoading(true);

            const response = await restAPI.get(`/provider-locations/?provider_id=${parseInt(authContext?.providerData?.id)}`);

            setLocationsList(response?.data?.results); // save all locations data in a list

            // prepare options for select
            setLocationsListOptions(
                response?.data?.results.map(location => ({
                    label: `${location?.state}, ${location?.city}, ${location?.postcode}, ${location?.address_1}  ${location?.address_2 || ''}`,
                    value: location.id
                }))
            );

        } catch (error) {

            console.log(error);

        } finally {
            setIsLoading(false);
        }
    }

    const onChangeMonthHandler = value => {
        const currentDate = dayjs(value);

        if (currentDate.isValid()) {
            const startDate = currentDate.startOf('month').format('YYYY-MM-DD');
            const endDate = currentDate.endOf('month').format('YYYY-MM-DD');

            setMonth({startDate, endDate});
            return;
        }
        setMonth(null);
    }

    const showTotalKitsForAllTime = () => {
        setMonth(null);
    }

    const loadAllProviderOrders = async () => {

        setIsLoading(true);

        try {

            let requestURL = ``; // result API request URL

            if (month !== null) {
                requestURL = `/kits/all/?provider=${authContext.providerData.id}`;
                requestURL += `&created__gte=${month?.startDate}`;
                requestURL += `&created__lte=${month?.endDate}`;
                requestURL += `&ordering=-created`;
            }

            if (month === null) {
                requestURL = `/kits/all/?provider=${authContext.providerData.id}`;
                requestURL += `&ordering=-created`;
            }

            const response = await restAPI.get(`${requestURL}`, authConfig)


            // const response = await restAPI.get(`/kits/all/?provider=${authContext.providerData.id}`, authConfig)

            setOrdersList(response?.data?.results);
            setTotalKitsValue(response.data.count);

        } catch (error) {

            // alert("we have error");
            console.log(error);
        } finally {
            setIsLoading(false);
        }

    };


    const getFinalKits = async () => {
        setIsLoading(true);

        try {

            let requestURL = ``; // result API request URL

            if (month !== null) {
                requestURL = `/kits/all/?status=Final&provider=${authContext.providerData.id}`;
                requestURL += `&created__gte=${month?.startDate}`;
                requestURL += `&created__lte=${month?.endDate}`;
                requestURL += `&ordering=-created`;
            }

            if (month === null) {
                requestURL = `/kits/all/?status=Final&provider=${authContext.providerData.id}`;
                requestURL += `&ordering=-created`;
            }

            const response = await restAPI.get(`${requestURL}`, authConfig);

            // const response = await restAPI.get(`/kits/all/?status=Final&provider=${authContext.providerData.id}`, authConfig);
            // setTotalKitsAvailable(totalKitsValue - response.data.count);

            setFinalizedKits(response.data.results);
            setTotalFinalized(response.data.count);

        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }

    };


    const getActiveKits = async () => {
        setIsLoading(true);

        try {

            let requestURL = ``; // result API request URL

            if (month !== null) {
                requestURL = `/kits/all/?status=Activated&provider=${authContext.providerData.id}`;
                requestURL += `&created__gte=${month?.startDate}`;
                requestURL += `&created__lte=${month?.endDate}`;
                requestURL += `&ordering=-created`;
            }

            if (month === null) {
                requestURL = `/kits/all/?status=Activated&provider=${authContext.providerData.id}`;
                requestURL += `&ordering=-created`;
            }

            const response = await restAPI.get(`${requestURL}`, authConfig);

            // const response = await restAPI.get(`/kits/all/?status=Activated&provider=${authContext.providerData.id}`, authConfig);

            setActivatedKits(response.data.results);
            setTotalActivated(response.data.count);

        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }

    };


    const getPendingKits = async () => {
        setIsLoading(true);

        try {

            let requestURL = ``; // result API request URL

            if (month !== null) {
                requestURL = `/kits/all/?status=Pending&provider=${authContext.providerData.id}`;
                requestURL += `&created__gte=${month?.startDate}`;
                requestURL += `&created__lte=${month?.endDate}`;
                requestURL += `&ordering=-created`;
            }

            if (month === null) {
                requestURL = `/kits/all/?status=Pending&provider=${authContext.providerData.id}`;
                requestURL += `&ordering=-created`;
            }

            const response = await restAPI.get(`${requestURL}`, authConfig);

            // const response = await restAPI.get(`/kits/all/?status=Pending&provider=${authContext.providerData.id}`, authConfig);

            setPendingKits(response.data.results);
            setTotalPending(response.data.count);

        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }

    };

    const getExpiredKits = async () => {
        setIsLoading(true);

        try {

            let requestURL = ``; // result API request URL

            if (month !== null) {
                requestURL = `/kits/all/?status=Expired&provider=${authContext.providerData.id}`;
                requestURL += `&created__gte=${month?.startDate}`;
                requestURL += `&created__lte=${month?.endDate}`;
                requestURL += `&ordering=-created`;
            }

            if (month === null) {
                requestURL = `/kits/all/?status=Expired&provider=${authContext.providerData.id}`;
                requestURL += `&ordering=-created`;
            }

            const response = await restAPI.get(`${requestURL}`, authConfig);

            // const response = await restAPI.get(`/kits/all/?status=Pending&provider=${authContext.providerData.id}`, authConfig);

            setExpiredKits(response.data.results);
            setTotalExpired(response.data.count);

        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }

    };

    // Expired


    const inventories = [{
        label: (<InventoryItem>
            <strong>Total kits</strong>
            <span>{totalKitsValue - totalExpired}</span>
        </InventoryItem>), value: 'Total',
    },
        // {
        //     label: (<InventoryItem>
        //         <strong>Expired</strong>
        //         <span>{totalExpired}</span>
        //     </InventoryItem>), value: 'Expired',
        // },

        {
            label: (<InventoryItem>
                <strong>In Stock</strong>
                <span>{totalPending}</span>
            </InventoryItem>), value: 'In Stock',
        },

        {
            label: (<InventoryItem>
                <strong>Activated</strong>
                <span>{totalActivated}</span>
            </InventoryItem>), value: 'Activated',
        },

        {
            label: (<InventoryItem>
                <strong>Finalized</strong>
                <span>{totalFinalized}</span>
            </InventoryItem>), value: 'Finalized',
        },

    ]

    useEffect(() => {
        (async () => {
            await loadAllProviderOrders();
            await getFinalKits();
            await getActiveKits();
            await getPendingKits();
            await getExpiredKits();
        })();
    }, [totalKitsValue, month]);

    // // Load all provider locations TODO - continue it with fresh mind
    // useEffect(() => {
    //     (async () => {
    //         await loadAllProviderLocations();
    //     })();
    // }, []);

    return <Spin tip="Loading..." spinning={isLoading} indicator={<LoadingOutlined/>}>
        <Row style={{margin: '0 10px'}}>

            <Col xs={24} lg={24} xl={24}>
                <MainPageTitle>
                    {inventory.title}
                </MainPageTitle>
            </Col>


            <Col xs={24} lg={24} xl={24}>
                <Row justify={'start'}>

                    <Col xs={24} xxlg={24} lg={24} xl={24} md={24} sm={24}>
                        <MySegmentedElement
                            onChange={value => setCurrentInventory(value)}
                            options={inventories}
                        />
                    </Col>

                    <Col xs={24} xxlg={24} lg={24} xl={24} md={24} sm={24}>

                        <Space style={{margin: '0 0 20px 0'}}>

                            <span>Filter kits by month:</span>
                            {/*<span style={{fontSize: 11}}>{month?.startDate} - {month?.endDate}</span>*/}

                            <DatePicker
                                // defaultValue={dayjs()}
                                onChange={onChangeMonthHandler}
                                format={'YYYY-MM'}
                                picker="month"
                                inputReadOnly={true}
                                size={'small'}
                            />


                            {month !== null &&
                                <>
                                    <Divider type={'vertical'}/>
                                    <Button type={'link'} onClick={showTotalKitsForAllTime} style={{color: '#01a9ac'}}>
                                        Show all kits
                                    </Button>
                                </>
                            }
                            {/*<Divider type={'vertical'}/>*/}

                            {/*<span>Filter by location:</span>*/}
                            {/*<Select*/}
                            {/*    disabled={isLoading}*/}
                            {/*    placeholder="Please choose location for order"*/}
                            {/*    style={{width: '100%'}}*/}
                            {/*    options={locationsListOptions}*/}
                            {/*    onChange={setChosenLocation}*/}
                            {/*    value={chosenLocation}*/}
                            {/*/>*/}
                        </Space>
                    </Col>

                    <Col xs={24} xxlg={24} lg={24} xl={24} md={24} sm={24}>
                        {/* This guy decides who will be shown */}
                        <InventoryRouterComponent
                            authConfig={authConfig}
                            currentInventory={currentInventory}
                            totalKits={ordersList}
                            finalizedKits={finalizedKits}
                            activatedKits={activatedKits}
                            pendingKits={pendingKits}
                            expiredKits={expiredKits}
                            authContext={authContext}
                        />
                    </Col>
                </Row>

            </Col>
        </Row>
    </Spin>
}

export default Inventory;