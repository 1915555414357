import {useQuery} from 'react-query';
import apiService from "../../../../api/apiService";

import {getAllProviderProducts} from "../../../../api/productService";

const useLoadProducts = (providerId, isSelfPhlebotomy) => {
    // Load the products from the API

    if (!providerId) {
        throw new Error('Invalid providerId');
    }

    if (typeof providerId !== 'number') {
        throw new Error('Invalid providerId. We expect a number');
    }

    if (typeof isSelfPhlebotomy !== 'boolean') {
        throw new Error('Invalid isSelfPhlebotomy');
    }

    return useQuery('products', async () => {
        return await getAllProviderProducts(providerId, isSelfPhlebotomy);
    });
}

export default useLoadProducts;