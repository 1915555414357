import React from "react";
import pages from "../../../routing/config";
import {Col, Row, Tabs} from 'antd';
import MainPageTitle from "../../../components/MainPageTitle";
import PersonalInformation from "./PersonalInformation";
import ChangePassword from "./ChangePassword";

const items = [
    {
        key: '1',
        label: `Personal info`,
        children: <PersonalInformation/>,
    },

    {
        key: '2',
        label: `Change password`,
        children: <ChangePassword/>,
    },

    {
        key: '3',
        label: `Payment info`,
        children: `Content of Tab Pane 3`,
        disabled: true
    }
];

const Information = () =>
    <Row>
        <Col xs={24} lg={24} xl={24}>
            <MainPageTitle>{pages.information.title}</MainPageTitle>
        </Col>


        <Tabs defaultActiveKey="1" items={items}/>

    </Row>


export default Information;