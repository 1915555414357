import axios from 'axios';


const API_URL = 'https://django-api.efunctional.com/api/v0';

const apiService = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    }
});

const refreshAuthToken = async () => {
    const providerAuthContext = localStorage.getItem('providerAuthContext');
    if (providerAuthContext) {
        const {refresh} = JSON.parse(providerAuthContext);
        if (refresh) {
            try {
                // Replace with your actual refresh token endpoint
                const response = await axios.post(`${API_URL}/token/refresh/`, {refresh});
                const newAuthContext = {
                    ...JSON.parse(providerAuthContext),
                    access: response.data.access
                };
                localStorage.setItem('providerAuthContext', JSON.stringify(newAuthContext));
                return response.data.access;
            } catch (error) {
                console.error('Error refreshing token:', error);
                return Promise.reject(error);
            }
        }
    }
    // In case of no refresh token or failure, return null or handle appropriately
    return null;
};


// Add a request interceptor for adding access token to requests
apiService.interceptors.request.use((config) => {
    // (1) Get accessToken from localStorage
    const providerAuthContext = localStorage.getItem('providerAuthContext');

    // (2) Check is pasrsing is successful
    try {
        const context = JSON.parse(providerAuthContext);
        if (context?.access) {
            config.headers.Authorization = `ECL ${context.access}`;
        }
    } catch (error) {
        console.error('Error parsing providerAuthContext', error);
        return Promise.reject(error);
    }

    return config;
}, (error) => {
    return Promise.reject(error);
});


// Add a response interceptor for refreshing tokens
apiService.interceptors.response.use(response => response, async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const newAccessToken = await refreshAuthToken();
        if (newAccessToken) {
            originalRequest.headers.Authorization = `ECL ${newAccessToken}`;
            return apiService(originalRequest);
        }
    }
    return Promise.reject(error);
});

export default apiService;
