import React, {useCallback, useEffect, useState} from "react";
import {debounce} from "lodash";
import styled from "styled-components";
import useStore from "../../../store";
import useNewDirectOrderStore from "../store";
import {useInfiniteQuery} from "react-query";
import {loadAllProviderPatients} from "../../../../api/patientService";
import InfiniteScroll from "react-infinite-scroll-component";
import {CheckOutlined, DoubleLeftOutlined, DoubleRightOutlined, UnorderedListOutlined} from "@ant-design/icons";
import {
    Button,
    Col,
    Form,
    Input,
    Row,
    Select,
    Space,
    Typography,
    Modal,
    List,
    Divider,
    Spin,
    AutoComplete,
    message, Alert
} from "antd";
import {StatesAutoCompleteUseForm} from "../../../../components/StatesAutoComplete";
import phoneNumberMask from "./../utils/phoneNumberMask";
import dateOfBirthMask from "./../utils/dateOfBirthMask";
import apiService from "../../../../api/apiService";
import dayjs from "dayjs";


const CustomAutoComplete = styled(AutoComplete)`
    & {
        width: 100%;
        margin: 0 0 10px 0;
    }

    & button {
        background: linear-gradient(to right, #01a9ac, #01dbdf);
    }

    & input, & input:focus {
        border-color: #01a9ac;
    }
`

const StyledInfiniteScroll = styled(InfiniteScroll)`
    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;

const AssignToPatientComponent = () => {
    /*  Step 2  */

    const [form] = Form.useForm();
    const [dateValue, setDateValue] = useState('');

    const [modalIsShowing, setModalIsShowing] = useState(false);
    const [hasShownError, setHasShownError] = useState(false);
    const [timer, setTimer] = useState(null);
    const [emailFromApi, setEmailFromApi] = useState('');

    const [isEmailValid, setIsEmailValid] = useState(null);

    const {
        newPatientData,
        setNewPatientData,
        returnToChooseTheProduct,
        moveToCheckoutStep,
        setIsLoading
    } = useNewDirectOrderStore(state => state);

    const [emailInput, setEmailInput] = useState();

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields();
            await setNewPatientData({...values, email: emailInput});
            await moveToCheckoutStep();
        } catch
            (error) {
            console.error('Form validation failed:', error);
        }
    }

    const [searchStr, setSearchStr] = useState("");
    const providerData = useStore(state => state.providerData);

    const {data: patientResponseData, error, fetchNextPage, hasNextPage, isLoading} = useInfiniteQuery(
        ['clients', providerData?.id, searchStr],
        ({pageParam = ''}) => loadAllProviderPatients(providerData?.id, searchStr, pageParam),
        {getNextPageParam: (lastPage) => lastPage.next ? lastPage.next : false}
    );

    const loadMorePatients = async () => {
        if (hasNextPage) {
            await fetchNextPage();
        }
    };

    const debouncedSearch = useCallback(debounce((nextValue) => {
        setSearchStr(nextValue);
    }, 500), []); // 500ms debounce time


    const handleSearch = value => {
        if (!containsCyrillic(value)) {
            debouncedSearch(value);
        } else if (!hasShownError) {
            message.error("Cyrillic characters are not supported in search.");
            setHasShownError(true);
        }
    };
    // ======== //

    useEffect(() => {
        // Reset the error shown a flag whenever the search string changes
        setHasShownError(false);
    }, [searchStr]);


    const containsCyrillic = (text) => {
        const cyrillicPattern = /[\u0400-\u04FF]/;
        return cyrillicPattern.test(text);
    };

    const handleManualInput = (e) => {
        const maskedValue = dateOfBirthMask(e.target.value);
        setDateValue(maskedValue);

        form.setFieldValue('date_of_birth', maskedValue);
    };

    const validateDate = (_, value) => {
        if (!value || dayjs(value, 'MM-DD-YYYY', true).isValid()) {
            return Promise.resolve();
        }
        return Promise.reject(new Error('Please enter a valid date'));
    };


    // fix bug on any devices with scroll
    if (modalIsShowing) {
        document.body.style.overflow = "hidden";
    } else {
        document.body.style.overflow = "auto";
    }

    // -------------------------------------------------- //


    const prepareDataForExistedPatient = async () => {
        try {
            await setNewPatientData({...newPatientData, existedPatient: true});
            await moveToCheckoutStep();
        } catch (error) {
            console.error('[prepareDataForExistedPatient] ::: ', error);
        }
    };

    const checkEmailInApi = async (email) => {
        try {
            // Replace with your API call
            const response = await apiService.get(`/users/?email=${email}`)
            setEmailFromApi(response.data.results[0]);
            // Check if the response length is exactly 1
            setIsEmailValid(response.data.results.length === 1);

            const patientData = await apiService.get(`/patients/all/?email=${email}`)
            // setPatientData(patientData.data.results[0]);

            setNewPatientData(patientData.data.results[0]); // new code
            form.resetFields(); // Reset form fields when email is invalid

        } catch (error) {
            console.error('Error checking email:', error);
            setIsEmailValid(false);
        } finally {
            setIsLoading(false);
        }
    };


    const emailValidationFunction = (email) => {
        // Email validation function
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
    };


    const handleEmailInputChange = ({target: {value}}) => {
        setEmailInput(value);
        setIsEmailValid(null); // Reset validation status when input changes
        setEmailFromApi(null); // Reset email from API when input changes

        // Clear the previous timer
        if (timer) clearTimeout(timer);

        // Set a new timer for validation and API call
        setTimer(setTimeout(() => {
            if (emailValidationFunction(value)) {
                (async () => await checkEmailInApi(value))()
            }
        }, 500));
    };

    const choosePatient = email => {
        // if we choose a patient from the list, then we need to set email to input
        handleEmailInputChange({target: {value: email}});
        setModalIsShowing(false);
        setIsLoading(true);
    }

    const renderNavButtons = (isReadyToCheckout = false, isNewPatient = true) => {
        return <Col lg={24} xs={24}>
            <Form.Item>
                <Space>
                    <Button
                        style={{marginTop: 30}}
                        onClick={returnToChooseTheProduct}
                        type={'dashed'}
                        icon={<DoubleLeftOutlined/>}
                    >
                        Return to products
                    </Button>

                    {isReadyToCheckout
                        ? <Button
                            style={{marginTop: 30}}
                            onClick={isNewPatient ? handleSubmit : prepareDataForExistedPatient}
                            type={'primary'}
                            icon={<DoubleRightOutlined/>}
                        >
                            Go to checkout
                        </Button>
                        : null
                    }

                </Space>
            </Form.Item>
        </Col>
    }

    const renderCheckComponent = () => {
        // Render component for checking email in the system.

        // If email in the system, we can't create a new patient account -> show the message
        if (!isLoading && isEmailValid === true && newPatientData && newPatientData?.email) {
            return <Row>
                <Col>
                    Patient with this email already in system, and you can make an order for him
                </Col>

                {renderNavButtons(true, false)}
            </Row>
        }

        // If email not in the system, we can create a new patient account -> show the form
        if (!isLoading && isEmailValid === false) {
            return <>

                {/*<Typography.Paragraph style={{color: "red"}}>*/}
                {/*    If email not in the system, we can create a new patient account -> show the form*/}
                {/*</Typography.Paragraph>*/}


                <Form
                    name={'assign-to-patient'}
                    form={form}
                    style={{marginTop: 30}}
                    layout="vertical"
                    onFinish={handleSubmit}
                    autoComplete="off"
                    initialValues={newPatientData || {}} // Setting default values
                >
                    <Row gutter={16}>
                        <Col lg={8} xs={24}>
                            <Form.Item
                                name="first_name"
                                label="First name"
                                rules={[{
                                    required: true, message: 'Please input first name',
                                },]}
                            >
                                <Input placeholder={'First name'}/>
                            </Form.Item>
                        </Col>

                        <Col lg={8} xs={24}>
                            <Form.Item
                                name="last_name"
                                label="Last name"
                                rules={[{required: true, message: ''}]}
                            >
                                <Input placeholder={'Last name'}/>
                            </Form.Item>
                        </Col>

                        <Col lg={8} xs={24}>
                            <Form.Item
                                name="gender"
                                label="Sex"
                                rules={[{required: true, message: "Can't be empty"}]}
                            >
                                <Select
                                    menuItemSelectedIcon={<CheckOutlined/>}
                                    placeholder="Please choose a sex"
                                    defaultOpen={0}
                                    style={{width: '100%'}}
                                    options={[{label: "Male", value: "M"}, {label: "Female", value: "F"},]}
                                />
                            </Form.Item>
                        </Col>

                        <Col lg={8} xs={24}>
                            <Form.Item
                                name="date_of_birth"
                                label="Date of Birth"
                                rules={[
                                    {required: true, message: 'Please enter your date of birth'},
                                    {validator: validateDate,}
                                ]}
                            >
                                <Input
                                    value={dateValue}
                                    onChange={handleManualInput}
                                    placeholder="MM-DD-YYYY"
                                />
                            </Form.Item>
                        </Col>


                        <Col lg={8} xs={24}>
                            <Form.Item
                                name="phone"
                                label="Phone"
                                rules={[
                                    {required: true, message: 'Please enter phone',},
                                    {
                                        pattern: /\((\d{3})\)\s(\d{3})-(\d{4})/,
                                        message: 'Please enter valid phone number',
                                    }
                                ]}
                            >
                                <Input
                                    onChange={({target: {value}}) => {
                                        const prettyPhoneNumber = phoneNumberMask(value);

                                        form.setFieldValue('phone', prettyPhoneNumber);
                                    }}
                                    placeholder="Please enter phone"
                                />
                            </Form.Item>
                        </Col>

                        <Col lg={10} xs={24}>
                            <Form.Item
                                name="address"
                                label="Address"
                                rules={[{
                                    required: true, message: 'Please input an address',
                                },]}
                            >
                                <Input placeholder={'Address'}/>
                            </Form.Item>
                        </Col>

                        <Col lg={6} xs={24}>
                            <Form.Item
                                name="city"
                                label="City"
                                rules={[{required: true, message: 'Please input a city'}]}
                            >
                                <Input placeholder={'City'}/>
                            </Form.Item>
                        </Col>

                        <Col lg={4} xs={24}>
                            <StatesAutoCompleteUseForm form={form}/>
                        </Col>


                        <Col lg={4} xs={24}>
                            <Form.Item
                                name="postcode"
                                label="Zip code"
                                rules={[{required: true, message: 'Please input a zip'}]}
                            >
                                <Input placeholder={'Zip code'}/>
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>


                {renderNavButtons(true, true)}

            </>
        }

        // If email in the system, we can't create a new patient account -> show the message
        if (!isLoading && isEmailValid === true && !newPatientData && !newPatientData?.email) {
            return <Row>

                <Col>
                    <Alert
                        closable={true}
                        type={'error'}
                        message={<>
                            Can't create an order for this email.&nbsp;&nbsp;
                            <Button size={'small'} type={'text'}
                                    onClick={() => {
                                        setIsEmailValid(null);
                                        setEmailInput('');
                                    }}>
                                Clear email input
                            </Button></>}
                    />


                    {renderNavButtons(false, false)}
                </Col>
            </Row>
        }


        return <Row><Col>{renderNavButtons(false, false)}</Col></Row>
    }


    return <>
        <Typography.Title level={4} style={{fontWeight: 400, marginTop: 30}}>Assign to patient</Typography.Title>


        <Modal
            open={modalIsShowing}
            onCancel={() => setModalIsShowing(false)}
            title="My clients"
            destroyOnClose={true}
            footer={null}
            maskClosable={false}
            keyboard={true}
            closable={true}
        >

            <Row>
                <Col xs={24}>
                    <Typography.Paragraph>
                        Please select a client from the list for create an order for them.
                    </Typography.Paragraph>
                </Col>

                <Col xs={24} lg={24} xl={24}>
                    <CustomAutoComplete>
                        <Input.Search
                            size="medium"
                            placeholder="Search ..."
                            enterButton
                            value={searchStr}
                            onChange={(e) => handleSearch(e.target.value)}
                        />
                    </CustomAutoComplete>
                </Col>

                <Col xs={24} lg={24} xl={24}>
                    {patientResponseData?.pages?.flatMap(page => page.results).length !== undefined ? (
                        <StyledInfiniteScroll
                            height={"50vh"}
                            dataLength={patientResponseData?.pages?.flatMap(page => page.results).length}
                            next={loadMorePatients}
                            hasMore={hasNextPage}
                            endMessage={<Divider/>}
                            loader={
                                <Typography.Paragraph style={{textAlign: "center"}}>
                                    Loading ...</Typography.Paragraph>
                            }
                        >
                            <List
                                style={{minHeight: "43vh"}}
                                size={'small'}
                                loading={isLoading}
                                itemLayout="horizontal"
                                rowKey={(patient) => patient.id}
                                dataSource={patientResponseData?.pages?.flatMap(page => page.results)}
                                renderItem={(patient, index) =>
                                    <List.Item
                                        key={index}
                                        extra={
                                            <Button size={"small"} type={"primary"} icon={<DoubleRightOutlined/>}
                                                    onClick={() => choosePatient(patient.email)}
                                            >
                                                Choose
                                            </Button>
                                        }
                                    >
                                        <List.Item.Meta
                                            title={`${patient.first_name} ${patient.last_name}`}
                                            description={patient.email}
                                        />
                                    </List.Item>
                                }
                            />
                        </StyledInfiniteScroll>
                    ) : (
                        <Space style={{width: '100%', justifyContent: 'center'}}><Spin/></Space>
                    )}
                </Col>

            </Row>

        </Modal>


        <Row gutter={16}>
            <Col lg={24} xs={24}>
                <Form>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter email',
                            },
                            {
                                pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                                message: 'Please enter valid email',
                            },
                        ]}
                    >
                        <Row gutter={16}>
                            <Col xs={20}>
                                <Input
                                    name="email"
                                    allowClear={true}
                                    placeholder="Please enter email"
                                    value={emailInput}
                                    onChange={handleEmailInputChange}
                                />
                            </Col>

                            <Col xs={4}>
                                <Button
                                    onClick={() => setModalIsShowing(true)}
                                    icon={<UnorderedListOutlined/>}/>
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
            </Col>

            <Col lg={24} xs={24}>
                {renderCheckComponent()}
            </Col>

        </Row>
    </>
};


export default AssignToPatientComponent;


{/*<Col lg={24} xs={24}>*/
}
{/*    <Typography.Title level={5} style={{fontWeight: 400, marginTop: 30}}>DEBUG</Typography.Title>*/
}

{/*    <Typography.Paragraph>*/
}
{/*        Shipping addresses:*/
}
{/*        <pre>{JSON.stringify(newPatientData?.shipping_addresses, null, 2)}</pre>*/
}
{/*        <br/>*/
}

{/*        existedPatient:*/
}
{/*        <pre>{JSON.stringify(newPatientData?.existedPatient, null, 2)}</pre>*/
}
{/*    </Typography.Paragraph>*/
}
{/*</Col>*/
}