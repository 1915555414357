import apiService from './apiService';

export const loadAllProviderPatients = async (providerId, searchStr = '', url = '') => {
    try {
        const response = url
            ? await apiService.get(url)
            // : await apiService.get(`/patients/all/?ordering=-user__last_login&page=1&search=${searchStr}`);
            : await apiService.get(`/patients/all/?providers=${providerId}&ordering=-user__last_login&page=1&search=${searchStr}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching orders:', error);
        throw new Error('Failed to fetch clients information. Please try again later.', {originalError: error});
    }
};

export const loadKitsForCurrentPatient = async selectedPatient => {
    try {
        let resultRequestUrl = `/kits/all/?patient=${selectedPatient?.id}&ordering=-created`;

        const response = await apiService.get(resultRequestUrl);
        return response?.data?.results;

    } catch (error) {
        console.error('Error fetching kits:', error);
        throw new Error('Failed to fetch test kits information. Please try again later.', {originalError: error});
    }
}


export const getReportLink = async selectedKit => {
    try {
        const {id: kitID, patient: patientID, to_date, npi: doctorNPI} = selectedKit;
        const data = {kitID, patientID, doctorNPI, to_date};
        const response = await apiService.post(`/results/get-report-link/`, data)
        const {secureURL: reportUrl} = response.data;
        return reportUrl;
    } catch (error) {
        console.error('Error fetching report link:', error);
        throw new Error('Failed to fetch report link. Please try again later.', {originalError: error});
    }
}


export const makeNewVitaminLabsOrder = async (selectedKit, action, kindOfVlSupplies, countOfDaysForSupply, providerId) => {
    /*
        -> make request for creating a new order
        -> after an api have successfully answered
        -> redirect user to `VitaminLabs` with orderID in params
    */

    // const {first_name, last_name, id} = selectedKit?.patient_details;

    // Prepare data for VitaminLabs
    const data = {
        "partner_uuid": selectedKit?.id,
        "kind": kindOfVlSupplies,
        "days_supply": parseInt(countOfDaysForSupply),
        "provider_id": providerId,
        "meta": {
            first_name: selectedKit?.patient_details?.first_name,
            last_name: selectedKit?.patient_details?.last_name,
            "efunctionalID": selectedKit?.patient_details?.id,
        }
    }


    try {

        // Save order to Efunctional DB
        const response = await apiService.post('igs-vitamins/make-vitaminlab-order/', data);

        if (action === 'buy') {
            window.open(`https://getvitaminlab.com/efunctional-portal/?buy_now=true&formula_id=${response.data.orderID}`)
        } else if (action === 'modify') {
            window.open(`https://getvitaminlab.com/efunctional-portal/?formula_id=${response.data.orderID}`)
        }

    } catch (error) {
        console.error('Error raised when we try to create a Vitamin Order:', error);
        throw new Error('Failed to create a VL order. Please try again later.', {originalError: error});
    }

}

