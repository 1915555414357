import React from "react";
import TotalKitsTable from "./TotalKitsTable";

const InventoryRouterComponent = props => {

    const {
        currentInventory, totalKits, finalizedKits, activatedKits, pendingKits, expiredKits, authConfig, authContext
    } = props;

    // TODO maybe I need run here async request for getting data and push it to needed component
    switch (currentInventory) {
        case 'Total':
            return <TotalKitsTable data={totalKits} authConfig={authConfig} authContext={authContext}/>;

        case 'In Stock':
            return <TotalKitsTable data={pendingKits} authConfig={authConfig} authContext={authContext}/>;

        // case 'Expired':
        //     return <TotalKitsTable data={expiredKits} authConfig={authConfig} authContext={authContext}/>;

        case 'Activated':
            return <TotalKitsTable data={activatedKits} authConfig={authConfig} authContext={authContext}/>;

        case 'Finalized':
            return <TotalKitsTable data={finalizedKits} authConfig={authConfig} authContext={authContext}/>;

        default:
            return <TotalKitsTable data={totalKits} authConfig={authConfig} authContext={authContext}/>;
    }
}

export default InventoryRouterComponent;